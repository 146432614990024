import {
	For,
	Match,
	Show,
	Switch,
	createEffect,
	createMemo,
	createSignal,
} from "solid-js";
import { createMutable } from "solid-js/store";

import {
	PartnersCabinetPageLayout,
	UnisimCoin,
	usePK,
	formatNumberSplitThousands as format,
	routes,
	toISODateString,
} from "./common";
import { createDateRangePicker } from "./date_range_picker";
import { BasicInput, BasicInput2 } from "#/components/basic-input";
import {
	api,
	CURRENCY_SYMBOLS,
	formatFloatLike,
	isApiError,
	onPageWasNavigated,
	useRouter,
} from "#/lib/mod";
import { Dropdown, useDropdownContext } from "../../components/mod";

const PAYMENTS_CHUNK_COUNT = 3;

export function PaymentsPage() {
	const [downloadLoading, setDownloadLoading] = createSignal(false);

	const [requestPayoutAvailable, setRequestPayoutAvailable] =
		createSignal(false);
	let trace = tracing("PaymentsPage");
	let router = useRouter();
	let pk = usePK();

	createEffect(() => {
		if (pk.state.currency === "bonus") {
			if (
				payments.payment_value <= 0 ||
				!payments.metrics ||
				payments.payment_value > payments.metrics.amount_ready_to_payout ||
				!payments.target_imsi
			) {
				setRequestPayoutAvailable(false);
			} else {
				setRequestPayoutAvailable(true);
			}
		} else {
			if (
				payments.payment_value <= 0 ||
				!payments.metrics ||
				payments.payment_value > payments.metrics.amount_ready_to_payout
			) {
				setRequestPayoutAvailable(false);
			} else {
				setRequestPayoutAvailable(true);
			}
		}
	});

	let payments = createMutable({
		range: {
			start: null as number,
			end: null as number,
		},
		payment_value: 0,
		payment_esim_id: null as string,
		metrics: null as api.GetPayoutMetricsResponse,
		esims: null as api.GetEsimsResponse,
		target_imsi: null,
		create_payout_avaialble: false,
		list: {
			count: 0,
			offset: 0,
			results: [] as api.ListPayoutsResponse["results"],
		},
		bonusOperationsList: {
			count: 0,
			offset: 0,
			results: [] as api.GetOperationsResponse,
		},
	});
	let DateRangePicker = createDateRangePicker();

	const operations = createMemo(() => {
		if (
			pk.state.currency === "bonus" &&
			payments.bonusOperationsList.results.length
		) {
			return payments.bonusOperationsList.results.map((el) => {
				return {
					id: el.id,
					amount: el.amount,
					created_at: el.created_at,
				};
			}) as api.ListPayoutsResponse["results"];
		} else if (
			["rub", "usd"].includes(pk.state.currency) &&
			payments.list.results.length
		) {
			return payments.list.results as api.ListPayoutsResponse["results"];
		}
		return [] as api.ListPayoutsResponse["results"];
	});

	onPageWasNavigated((view, pview) => {
		let { start, end } = router.location.query;
		(start = Date.parse(start)), (end = Date.parse(end));

		if ([start, end].every((i) => !isNaN(i))) {
			DateRangePicker.start(start);
			DateRangePicker.end(end);
		}
		if (view.rpath !== pview?.rpath) {
			refetchMetrics();
			payoutListLoad();
			operationsListLoad();
		}
	});

	const operationsSpreadsheetDownloadAvailable = createMemo(() => {
		if (
			pk.state.currency === "bonus" &&
			payments.bonusOperationsList?.results.length
		) {
			return true;
		}

		if (
			["usd", "rub"].includes(pk.state.currency) &&
			payments.list?.results.length
		) {
			return true;
		}

		return false;
	});

	const downloadPayoutsSpreadsheet = async () => {
		if (downloadLoading()) {
			return;
		}
		setDownloadLoading(true);
		let result = null;
		if (pk.state.currency === "bonus") {
			result = await api.getOperationsSpreadsheet({
				scope: "bonuses",
				only_credit: true,
			});
		} else {
			result = await api.getPayoutsSpreadsheet({});
		}
		const file = window.URL.createObjectURL(result);
		window.location.assign(file);
		setDownloadLoading(false);
	};

	async function refetchMetrics() {
		if (!pk.state.partnership_id) {
			return;
		}
		let response = await api.getPayoutMetrics({});
		payments.metrics = response;
		trace.debug("fetched payment metrics");
	}

	const refetchEsims = async () => {
		if (!pk.state.partnership_id && pk.state.currency === "bonus") {
			return;
		}
		let response = await api.getEsims();
		payments.esims = response;
		if (
			payments.esims &&
			payments.esims.results &&
			payments.esims.results.length !== 0
		) {
			pk.state.dev_has_esims = true;
		}
		{
			pk.state.dev_has_esims = false;
		}
		trace.debug("fetched esims");
	};

	createEffect(async () => {
		if (pk.state.partnership_id) {
			await refetchMetrics();
			await refetchEsims();
		}
	});

	async function requestPayout() {
		if (pk.state.partnership_id && requestPayoutAvailable()) {
			if (pk.state.currency === "bonus") {
				trace.debug("requesting esim topup");
				await api.createOrder({
					product: "esim_balance",
					imsi: payments.target_imsi,
					balance_amount_to_use: payments.payment_value,
					bank_id: "stripe",
					user_lang: "ru",
				});
				await refetchEsims();
				await refetchMetrics();
				await operationsListLoad();
			} else {
				trace.debug("requesting payout");
				let response = await api.requestPayout({
					amount: String(payments.payment_value),
				});
				trace.debug("request payout response", response);
				await refetchMetrics();
				await payoutListLoad();
			}
		}
	}

	async function payoutListLoad() {
		payments.list.offset = 0;
		payments.list.results = [];
		let response = await api.listPayouts({
			offset: payments.list.offset,
			limit: PAYMENTS_CHUNK_COUNT,
		});
		let { results, ...cursor } = response;
		payments.list.count = cursor.count;
		payments.list.offset = results.length;
		payments.list.results.push(...results);
	}

	async function payoutListLoadMore() {
		let response = await api.listPayouts({
			offset: payments.list.offset,
			limit: PAYMENTS_CHUNK_COUNT,
		});
		let { results, ...cursor } = response;
		payments.list.offset += results.length;
		payments.list.results.push(...results);
	}

	async function operationsListLoad() {
		payments.bonusOperationsList.results = [];
		let response = await api.getOperations({
			scope: "bonuses",
			only_credit: true,
		});
		if (isApiError(response)) {
			return;
		}
		payments.bonusOperationsList.results = response;
	}

	function MetricCard(props) {
		return (
			<card class=":c: h-65 flex flex-col justify-between bg-#2C2C2C p-6 rounded-16px <md:(p-4 rounded-12px h-22.5)">
				<div
					children={props.title}
					class=":c: text-6 font-600 leading-30px c-white <md:(text-4 font-600 leading-20px)"
				/>
				<div
					children={props.value}
					class=":c: text-15 font-700 leading-68px c-#D6FE1E <md:(text-5 font-700 leading-18px)"
				/>
			</card>
		);
	}

	function onInput({ currentTarget: { value } }) {
		if (value === "") {
			payments.payment_value = 0;
		}

		let as_number = Number(value.replace(",", "."));
		if (isNaN(as_number) || as_number < 0 || as_number > 1_000_000) {
			payments.payment_value = 0;
			return;
		}
		payments.payment_value = as_number;
	}

	function filterSpecialKeys(e: KeyboardEvent) {
		let has_modifier = e.ctrlKey || e.metaKey;
		if (has_modifier && e.code === "KeyA") return;
		if (has_modifier && e.code === "KeyV") return;
		if (has_modifier && e.code === "KeyC") return;
		if (has_modifier && e.code === "KeyX") return;

		if (
			![
				"0",
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				".",
				",",
				"Process",
				"Backspace",
				"Delete",
				"ArrowLeft",
				"ArrowRight",
			].includes(e.key)
		)
			e.preventDefault();
	}

	function FiatPayment() {
		return (
			<fiatpayment class=":c: self-stretch m-t-25 <md:m-t-6">
				<div class=":c: text-12 <md:text-6 font-700">
					Оформление выплаты
				</div>
				<div class=":c: flex gap-4 <md:flex-wrap m-t-12 <md:m-t-6">
					<div class=":c: w-92.5 rounded-16px p-6 <md:p-4 h-auto bg-#D6FE1E">
						<BasicInput.Root class=":c: c-#808080 m-t-12.5 <md:m-t-0">
							<BasicInput2
								class=":c: uno-layer-v1:(text-4 leading-20px c-black) placeholder:c-#808080"
								inputmode="numeric"
								placeholder="0"
								onInput={onInput}
								onKeyDown={filterSpecialKeys}
							/>
							<BasicInput.IconW
								right
								x={0}
								Icon={(p) => (
									<div
										{...p}
										classList={{
											...p.classList,
											":c: text-6 font-300 leading-30px flex-center":
												true,
										}}
										children={CURRENCY_SYMBOLS[pk.state.currency]}
									/>
								)}
							/>
						</BasicInput.Root>
						<div
							innerText={"Запросить выплату"}
							onClick={requestPayout}
							classList={{
								":c: flex-center c-white bg-#000000 ptr select-none rounded-8px text-5 font-600 leading-25px p-block-4 m-t-3":
									true,
								":c: <md:(p-block-3 text-4 leading-20px)":
									requestPayoutAvailable(),
								":c: hover:bg-#333333 active:bg-#191919":
									requestPayoutAvailable(),
								":c: cursor-default": !requestPayoutAvailable(),
								":c: uno-layer-v1:(c-#808080 bg-#C3CAD4)":
									!requestPayoutAvailable(),
							}}
						/>
					</div>

					<div class=":c: flex flex-col gap-2 p-inline-6 <md:p-inline-4">
						<Show when={payments.metrics}>
							<div
								class=":c: text-5 font-200 leading-25px [&>span]:font-400 <md:(text-4 font-300) break-all"
								style={{ "white-space": "pre-wrap" }}
							>
								<span>{payments.metrics.bank_details}</span>
							</div>
						</Show>
						<div class=":c: text-4 font-200 leading-20px c-#4D4D4D <md:(text-3.5 font-300 leading-17.5px)">
							Для изменения реквизитов напишите на 
							<a
								href="mailto:partners@unisim.net"
								class=":c: c-#9CBE00 hover:c-#6e8600 [&>svg>path]:hover:fill-#6e8600 ptr"
							>
								partners@unisim.net
							</a>
						</div>
					</div>
				</div>
			</fiatpayment>
		);
	}

	function BonusPayment() {
		return (
			<bonuspayment class=":c: self-stretch m-t-25 <md:m-t-6">
				<div class=":c: text-12 <md:text-6 font-700">
					Начисление бонусов
				</div>

				<Switch>
					<Match
						when={
							pk.state.currency === "bonus" &&
							payments.esims !== null &&
							payments.esims.results &&
							payments.esims.results.length
						}
					>
						<div class=":c: md:(flex gap-4 items-center) bg-#F1F3F6 rounded-16px p-6 m-t-12 <md:(rounded-12px p-4 m-t-6)">
							<BasicInput.Root class=":c: c-#808080 grow-2 lg:max-w-55 <md:m-t-2">
								<BasicInput2
									class=":c: uno-layer-v5:(h-12 b-none text-4 leading-20px c-black <md:(h-11 text-4 font-300 leading-20px)) placeholder:c-#808080"
									inputmode="numeric"
									placeholder="0"
									onInput={onInput}
									onKeyDown={filterSpecialKeys}
								/>
								<BasicInput.IconW
									right
									x={0}
									iconc=":c: uno-layer-v3:(size-3.5)"
									Icon={(p) => (
										<svg viewBox="0 0 11 12" fill="none" {...p}>
											<path
												d="M7.7225 8.98886C8.11178 8.94782 8.64618 8.82557 9.04385 8.38293C9.23714 8.16993 9.46936 7.90372 9.57573 7.49283C9.65103 7.20193 9.65028 6.86052 9.64957 6.53506L9.64957 6.53462C9.6495 6.50338 9.64943 6.47229 9.64943 6.44141V0H11.0001V6.44141C11.0001 7.80314 10.605 8.73607 9.92032 9.37523C9.41775 9.84035 8.70563 10.1744 7.7225 10.3194V12H6.47389V10.3997H5.06919V12H3.82058V10.3199C2.83252 10.1753 2.11812 9.84115 1.61522 9.37616C0.935952 8.73744 0.542969 7.80413 0.542969 6.44141V0H1.89365V6.44141C1.89365 6.46801 1.89361 6.49472 1.89357 6.52152C1.89306 6.85479 1.89253 7.20017 1.97039 7.49626C2.07755 7.90379 2.30775 8.16871 2.49635 8.37972L2.49875 8.3824L2.50117 8.38507C2.8995 8.82387 3.42961 8.94706 3.82058 8.98862V4.50137H5.06919V9.00278C5.07538 9.00276 5.08148 9.00275 5.08749 9.00275H6.45559C6.4616 9.00275 6.4677 9.00276 6.47389 9.00278L6.47389 4.50137H7.7225L7.7225 8.98886Z"
												fill="#808080"
											/>
										</svg>
									)}
								/>
							</BasicInput.Root>
							<Dropdown
								options={payments.esims.results}
								search={(value) =>
									payments.esims.results.filter((e) =>
										[e.imsi, e.alias].some((str) =>
											str.includes(value),
										),
									)
								}
								display={(esim) => `${esim.imsi} / ${esim.alias}`}
								apply={(esim, ctx) => {
									if (!esim) {
										ctx.input.value = "";
										return;
									}
									payments.target_imsi = esim.imsi;
									payments.payment_esim_id = esim.id;
									ctx.input.value = `${esim.imsi} / ${esim.alias}`;
								}}
								// error={err.imsi}
								afterSelect={(esim, text, ctx) => {
									if (!esim) {
										ctx.input.value = "";
										return;
									}
									payments.payment_esim_id = esim.id;
									payments.target_imsi = esim.imsi;
									ctx.input.value = `${esim.imsi} / ${esim.alias}`;
								}}
								Root={(p) => (
									<div
										{...p}
										classList={{
											...p.classList,
											":c: uno-layer-v3:(rounded-8px b-none grow-6 <md:(text-4 font-300 leading-20px m-t-2))":
												true,
										}}
									/>
								)}
								Input={(p) => (
									<BasicInput
										{...p}
										placeholder="eSIM для пополнения"
										classList={{
											...p.classList,
											":c: uno-layer-v2:(h-12 bg-white rounded-6px b-(1px solid #C3CAD4) font-300 text-4 <md:h-11)":
												true,
										}}
										// placeholder={t().esim_placeholder}
										// value={state.esim?.id}
									/>
								)}
								Suggestions={(p) => (
									<Dropdown.Suggestions
										{...p}
										classList={{
											...p.classList,
											":c: uno-layer-v3:(b-unset bg-white backdrop-blur-none)":
												true,
										}}
										Suggestion={(p) => {
											let ctx = useDropdownContext();
											return (
												<Dropdown.Suggestion
													{...p}
													classList={{
														...p.classList,
														":c: uno-layer-v3:(b-unset)": true,
														":c: uno-layer-v3:(bg-gray-100)":
															ctx.hover === p.item,
													}}
												/>
											);
										}}
									/>
								)}
							/>
							<div
								innerText={"Списать"}
								onClick={requestPayout}
								classList={{
									":c: w-auto h-12 flex-center p-inline-10 c-white bg-#000000 ptr select-none rounded-8px text-5 font-600 leading-25px grow-1":
										true,
									":c: <md:(p-block-3 text-4 leading-20px m-t-4)":
										requestPayoutAvailable(),
									":c: hover:bg-#333333 active:bg-#191919":
										requestPayoutAvailable(),
									":c: cursor-default": !requestPayoutAvailable(),
									":c: uno-layer-v1:(c-#808080 bg-#C3CAD4)":
										!requestPayoutAvailable(),
								}}
							/>
						</div>
					</Match>
					<Match when={!pk.state.dev_has_esims}>
						<div
							classList={{
								":c: w-auto max-w-85 h-18 bg-green-500 m-t-8": true,
								":c: flex-center gap-2.5 rounded-2 select-none relative ptr":
									true,
								":c: text-5 font-600 c-#2C2C2C leading-25px": true,
							}}
							children={[
								<i class=":c: i-local:sim w-7 c-[#525252]" />,
								"Оформить eSIM",
							]}
							ref={ripple}
							onClick={() =>
								window.open("https://unisim.net/ru#buy-esim", "_blank")
							}
						/>
						<div class=":c: text-6 font-200 leading-30px c-#4D4D4D m-t-5 <md:(text-4 font-300 leading-20px m-t-4)">
							Оформите eSIM, чтобы вывести бонусы
						</div>
					</Match>
				</Switch>
			</bonuspayment>
		);
	}

	return (
		<PartnersCabinetPageLayout>
			<Show
				when={payments.metrics}
				children={(function () {
					let Postfix = () => [
						" ",
						<Show
							when={pk.state.currency === "bonus"}
							fallback={CURRENCY_SYMBOLS[pk.state.currency]}
						>
							<UnisimCoin class=":c: size-9 <md:size-3.5 [&>path]:(fill-#D6FE1E stroke-#D6FE1E stroke-width-0.4)" />
						</Show>,
					];
					return (
						<metrics class=":c: self-stretch m-t-25 <md:m-t-12">
							<div class=":c: text-12 <md:text-6 font-700">Метрики</div>
							<cards class=":c: w-full grid grid-cols-3 gap-4 m-t-12 <lg:(grid grid-cols-1) <md:(flex-wrap m-t-6) [&>card]:min-w-[min(100%,23.125rem)]">
								<MetricCard
									title="Доступно к выплате"
									value={[
										format(
											payments.metrics.amount_ready_to_payout,
											pk.state.currency,
										),
										<Postfix />,
									]}
								/>
								<MetricCard
									title="Ожидает подтверждения"
									value={[
										format(
											payments.metrics.amount_pending,
											pk.state.currency,
										),
										<Postfix />,
									]}
								/>
								<MetricCard
									title="Выплачено за все время"
									value={[
										format(
											payments.metrics.amount_payed_out,
											pk.state.currency,
										),
										<Postfix />,
									]}
								/>
							</cards>
						</metrics>
					);
				})()}
			/>
			<Switch>
				<Match when={pk.state.currency === "bonus"}>
					<BonusPayment />
				</Match>
				<Match when={["rub", "usd"].includes(pk.state.currency)}>
					<FiatPayment />
				</Match>
			</Switch>
			<history class=":c: self-stretch m-t-12 <md:m-t-12 m-b-12">
				<div class=":c: text-12 <md:text-6 font-700 flex flex-wrap gap-6 items-center justify-between">
					<div>История операций</div>
					<div
						onClick={() => downloadPayoutsSpreadsheet()}
						classList={{
							":c: w-193px p-inline-10 p-block-4 text-5 font-600 leading-25px flex-center gap-2":
								true,
							":c: bg-#D6FE1E hover:bg-#e2fe61 active:bg-#c1e51b": true,
							":c: rounded-8px select-none relative ptr <md:(w-full p-block-3 text-4 leading-20px)":
								true,
							":c: uno-layer-v1:(bg-#C3CAD4 c-#808080) [&>svg>path]:fill-#808080!":
								!operationsSpreadsheetDownloadAvailable(),
						}}
					>
						Скачать
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
							<path
								d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"
								fill="black"
							/>
						</svg>
					</div>
				</div>
				<div class=":c: m-t-6">
					<For
						each={operations()}
						children={(payout) => (
							<div class=":c: flex items-center justify-between p-block-3 [&:not(:last-child)]:b-b-(1px solid gray-200)">
								<div class=":c: flex flex-col gap-2">
									<div class=":c: text-5 font-200 leading-25px <md:(text-3.5 font-300 leading-18px)">
										Списание
									</div>
									<div class=":c: text-3.5 font-200 leading-18px c-#4D4D4D <md:(text-3 font-300 leading-15px)">
										{new Date(payout.created_at).toLocaleString(
											"ru-RU",
											{ dateStyle: "short" },
										)}
									</div>
								</div>
								<div class=":c: text-6 leading-30px font-400 <md:(text-4 leading-20px font-400)">
									{formatFloatLike(payout.amount, "ru")} &nbsp;
									<Show
										when={pk.state.currency === "bonus"}
										fallback={CURRENCY_SYMBOLS[pk.state.currency]}
									>
										<UnisimCoin class=":c: size-4 <md:size-3.5 [&>path]:(fill-#000000 stroke-#000000 stroke-width-0.1)" />
									</Show>
								</div>
							</div>
						)}
						fallback={
							<div class=":c: flex flex-col justify-end items-center gap-6 min-w-42 h-39 font-200 text-6 c-#808080 leading-30px m-inline-auto <md:(text-5 leading-25px w-13)">
								<svg
									viewBox="0 0 56 45"
									fill="none"
									class=":c: w-13 h-auto"
								>
									<path
										opacity="0.6"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M7.40018 5.18218H7.3747C7.19464 5.21407 7.01637 5.25156 6.84016 5.29451C2.20636 6.3626 0.850816 10.2475 0.850816 12.5985V15.3144C0.811526 15.6063 0.791366 15.9015 0.791016 16.1987V37.7997C0.793266 39.7087 1.61304 41.5389 3.07048 42.8888C4.52792 44.2386 6.50398 44.9979 8.5651 45H47.4355C49.4967 44.9979 51.4727 44.2386 52.9302 42.8888C54.3876 41.5389 55.2074 39.7087 55.2096 37.7997V16.1987C55.2074 14.2897 54.3876 12.4595 52.9302 11.1096C51.4727 9.75971 49.4967 9.00044 47.4355 8.99835H16.3933C14.3422 8.99409 14.1187 6.68985 15.8622 6.29823H47.4355C47.8896 6.29803 48.3432 6.32471 48.7936 6.37811C48.6409 5.38555 48.2728 4.43192 47.7115 3.57469C47.1501 2.71746 46.4072 1.9744 45.5274 1.39031C44.6476 0.806213 43.6492 0.393183 42.5924 0.17612C41.5356 -0.0409433 40.4423 -0.0575433 39.3784 0.12732L8.68465 4.9808C8.25985 5.04096 7.83028 5.10783 7.40018 5.18218ZM39.5062 29.9926C40.1454 30.3882 40.8969 30.5993 41.6657 30.5993C42.6966 30.5993 43.6853 30.22 44.4143 29.5449C45.1432 28.8697 45.5528 27.954 45.5528 26.9992C45.5528 26.2871 45.3248 25.5911 44.8977 24.999C44.4706 24.407 43.8635 23.9455 43.1532 23.6731C42.443 23.4006 41.6614 23.3293 40.9074 23.4682C40.1534 23.6071 39.4608 23.95 38.9172 24.4535C38.3735 24.957 38.0033 25.5985 37.8534 26.2968C37.7034 26.9952 37.7803 27.7191 38.0746 28.3769C38.3688 29.0347 38.867 29.597 39.5062 29.9926Z"
										fill="#808080"
									/>
								</svg>
								<div>Операций нет</div>
							</div>
						}
					/>
				</div>

				<Show
					when={
						payments.list &&
						payments.list.count > payments.list.results.length
					}
				>
					<div
						class=":c: flex-center gap-1 underline font-200 text-6 leading-30px <md:(text-4 font-300 leading-20px) ptr"
						onClick={payoutListLoadMore}
					>
						<svg
							width="19"
							height="18"
							viewBox="0 0 19 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="1"
								y="0.5"
								width="17"
								height="17"
								rx="8.5"
								stroke="black"
							/>
							<path
								d="M8.875 5.25H10.125V12.75H8.875V5.25Z"
								fill="black"
							/>
							<path
								d="M5.75 8.375H13.25V9.625H5.75V8.375Z"
								fill="black"
							/>
						</svg>
						Больше
					</div>
				</Show>
			</history>
		</PartnersCabinetPageLayout>
	);
}

let InfoIcon = (p) => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...p}>
		<path
			d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17716 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75ZM8.75 6.5625C8.75 6.37708 8.80499 6.19582 8.908 6.04165C9.01101 5.88748 9.15743 5.76732 9.32874 5.69636C9.50004 5.62541 9.68854 5.60684 9.8704 5.64301C10.0523 5.67919 10.2193 5.76848 10.3504 5.89959C10.4815 6.0307 10.5708 6.19775 10.607 6.3796C10.6432 6.56146 10.6246 6.74996 10.5536 6.92127C10.4827 7.09257 10.3625 7.23899 10.2084 7.342C10.0542 7.44502 9.87292 7.5 9.6875 7.5C9.43886 7.5 9.20041 7.40123 9.02459 7.22541C8.84878 7.0496 8.75 6.81114 8.75 6.5625Z"
			fill="#ABABAB"
		/>
	</svg>
);
