import {
	createEffect,
	createMemo,
	createSignal,
	createUniqueId,
	Index,
	Show,
} from "solid-js";
import CorvuCalendar, { RootSingleProps } from "@corvu/calendar";
import Popover from "@corvu/popover";
import type { ParentComponent, VoidComponent } from "solid-js";
import "./styles.css";
import { CaretLeft, CaretRight } from "./icons";

export const Calendar: ParentComponent<{
	onValueChange: RootSingleProps["onValueChange"];
	value: Date;
}> = (calendarProps) => {
	const labelId = createUniqueId();
	const [month, setMonth] = createSignal(new Date());

	createEffect(() => {
		setMonth(calendarProps.value);
	});

	return (
		<CorvuCalendar
			mode="single"
			labelIds={[labelId]}
			month={month()}
			onMonthChange={setMonth}
			value={calendarProps.value}
			onValueChange={calendarProps.onValueChange}
		>
			{(props) => (
				<Popover
					placement="bottom-start"
					floatingOptions={{
						offset: 5,
						flip: true,
					}}
					initialFocusEl={props.focusedDayRef ?? undefined}
					labelId={labelId}
				>
					<Popover.Trigger class="my-auto flex w-fit items-center space-x-2 rounded-md px-3 py-2 transition-all duration-100 hover:bg-gray-50 border-gray-200">
						{calendarProps.children}
					</Popover.Trigger>
					<Popover.Portal>
						<Popover.Content class="z-50 rounded-lg bg-white shadow-md data-open:animate-in data-open:fade-in-50% data-open:slide-in-from-top-1 data-closed:animate-out data-closed:fade-out-50% data-closed:slide-out-to-top-1">
							<div class="rounded-md bg-white p-3 shadow-md">
								<div class="flex items-center justify-between gap-4">
									<CorvuCalendar.Nav
										action="prev-month"
										aria-label="Go to previous month"
										class="size-7 b-none rounded-md bg-white p-1.25 hover:bg-gray-200"
									>
										<CaretLeft size="18" />
									</CorvuCalendar.Nav>
									<CorvuCalendar.Label
										as={Popover.Label}
										class="text-sm"
									>
										{formatMonth(props.month)}{" "}
										{props.month.getFullYear()}
									</CorvuCalendar.Label>
									<CorvuCalendar.Nav
										action="next-month"
										aria-label="Go to next month"
										class="size-7 b-none rounded-md bg-white p-1.25 hover:bg-gray-200"
									>
										<CaretRight size="18" />
									</CorvuCalendar.Nav>
								</div>
								<CorvuCalendar.Table class="mt-3">
									<thead>
										<tr>
											<Index each={props.weekdays}>
												{(weekday) => (
													<CorvuCalendar.HeadCell
														abbr={formatWeekdayLong(weekday())}
														class="w-8 flex-1 pb-1 text-xs font-normal opacity-65"
													>
														{formatWeekdayShort(weekday())}
													</CorvuCalendar.HeadCell>
												)}
											</Index>
										</tr>
									</thead>
									<tbody>
										<Index each={props.weeks}>
											{(week) => (
												<tr>
													<Index each={week()}>
														{(day) => (
															<CorvuCalendar.Cell class="p-0">
																<CorvuCalendar.CellTrigger
																	day={day()}
																	class={
																		"___calendar_cell :c: inline-flex size-8 items-center justify-center rounded-md text-sm focus-visible:bg-gray-200/80 disabled:pointer-events-none disabled:opacity-40"
																	}
																>
																	{day().getDate()}
																</CorvuCalendar.CellTrigger>
															</CorvuCalendar.Cell>
														)}
													</Index>
												</tr>
											)}
										</Index>
									</tbody>
								</CorvuCalendar.Table>
							</div>
						</Popover.Content>
					</Popover.Portal>
				</Popover>
			)}
		</CorvuCalendar>
	);
};

const { format: formatWeekdayLong } = new Intl.DateTimeFormat("ru", {
	weekday: "long",
});
const { format: formatWeekdayShort } = new Intl.DateTimeFormat("ru", {
	weekday: "short",
});
const { format: formatMonth } = new Intl.DateTimeFormat("ru", {
	month: "long",
});
const { format: formatTrigger } = new Intl.DateTimeFormat("ru", {
	year: "numeric",
	month: "long",
	day: "numeric",
});
