export let replay = import.meta.env.DEV 
? null 
: await async function() {
	let { default: Tracker } = await import("@openreplay/tracker")

	return new Tracker({
		__DISABLE_SECURE_MODE: import.meta.env.DEV,
		projectKey: "uBp7hCTubKCeeS5kBAPO",
		canvas: {
			disableCanvas: true,
		},
		network: {
			capturePayload: true,
			ignoreHeaders: ["authorization", "Authorization", "Sec-WebSocket-Protocol", "sec-webSocket-protocol"],
			captureInIframes: false,
			failuresOnly: false,
			sessionTokenHeader: false,
		},
	})
}()