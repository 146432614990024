export const STACK_DURATION = 200
const EASE_IN_OUT = "ease-in-out"

export function highlight(element: HTMLElement) {
	let animation = element.animate(
		[
			{ filter: "invert(20%)", },
			{ filter: "invert(0%)", },
		],
		{ duration: 320, easing: "linear" },
	)
	return animation.finished
}

export function animateStackIn(back: HTMLElement, top: HTMLElement) {
	let bg = back.animate(
		[
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
			{ transform: `translate3d(-25vw, 0, 0)`, filter: `brightness(80%)` },
		],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let fg = top.animate(
		[{ transform: `translate3d(100vw, 0, 0)` }, { transform: `translate3d(0vw, 0, 0)` }],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	return Promise.all([bg.finished, fg.finished])
}

export function animateStackOut(back: HTMLElement, top: HTMLElement) {
	let bg = back.animate(
		[
			{ transform: `translate3d(-25vw, 0, 0)`, filter: `brightness(80%)` },
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
		],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let fg = top.animate(
		[{ transform: `translate3d(0vw, 0, 0)` }, { transform: `translate3d(100vw, 0, 0)` }],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	return Promise.all([bg.finished, fg.finished])
}

export function animateStackBottomSheetIn(back: HTMLElement, top: HTMLElement) {
	let bg = back.animate(
		[
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
			{ transform: `translate3d(0, 0, -100px)`, filter: `brightness(80%)` },
		],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let fg = top.animate(
		[{ transform: `translate3d(0, 100vh, 0)` }, { transform: `translate3d(0, 0vh, 0)` }],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let root = document.documentElement.animate([{ "background-color": `transparent` }, { "background-color": `#000000` }], { duration: STACK_DURATION, easing: EASE_IN_OUT })
	return Promise.all([bg.finished, fg.finished, root.finished])
}

export function animateStackBottomSheetOut(back: HTMLElement, top: HTMLElement) {
	let bg = back.animate(
		[
			{ transform: `translate3d(0, 0, -100px)`, filter: `brightness(80%)` },
			{ transform: `translate3d(0, 0, 0)`, filter: `brightness(100%)` },
		],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let fg = top.animate(
		[{ transform: `translate3d(0, 0vh, 0)` }, { transform: `translate3d(0, 100vh, 0)` }],
		{ duration: STACK_DURATION, easing: EASE_IN_OUT },
	)
	let root = document.documentElement.animate([{ "background-color": `#000000` }, { "background-color": `transparent` }], { duration: STACK_DURATION, easing: EASE_IN_OUT })
	return Promise.all([bg.finished, fg.finished, root.finished])
}

