import { createMemo, Show } from "solid-js"
import { Transition } from "solid-transition-group"

import { ROUTES, useRouter } from "#/lib/navigation/mod"
import { useAuth } from "#/lib/auth"
import { langs } from "#/lib/appearance/i18n"
import { signal } from "#/lib/mod"

export let MOBILE_NAVBAR_HEIGHT = "calc(5rem + env(safe-area-inset-bottom, 0))"

export function MobileBottomNavbar() {
	let auth = useAuth()
	let router = useRouter()

	let showOrderMenu = signal(false)

	let isActive = (path: string) => router.top?.pathname === path

	let t = langs({
		ru: {
			[ROUTES.main.template]: "Главная",
			[ROUTES.bonus_main.template]: "Бонусы",
			[ROUTES.order.template]: "eSIM",
			[ROUTES.help.template]: "Поддержка",
			[ROUTES.more.template]: "Ещё",

			"order": "Оформить",
			"topup": "Пополнить",
		},
		en: {
			[ROUTES.main.template]: "Main",
			[ROUTES.bonus_main.template]: "Bonus",
			[ROUTES.order.template]: "eSIM",
			[ROUTES.help.template]: "Support",
			[ROUTES.more.template]: "More",

			"order": "Order",
			"topup": "Topup",
		},
	})

	let name_class = ":c: text-2.5 font-300 c-gray-300"
	function SimpleMenuItem(props: { icon: string, text: string, onClick?: () => void, route?: string }) {
		let active = createMemo(isActive.bind(null, props.route))
		return (
			<div class=":c: flex flex-col items-center justify-between h-9 w-full ptr"
				onClick={() => router.switchTo({ path: props.route })}
			>
				<i classList={{
					[props.icon]: true,
					":c: size-5 c-gray-300": true,
					"uno-layer-v1:c-green-500": active(),
				}}
				/>
				<span
					classList={{ [name_class]: true, "uno-layer-v1:(c-green-500 font-500)": active() }}
					innerText={props.text}
				/>
			</div>
		)
	}

	return <div
		classList={{
			":c: fixed bottom-0 left-0 right-0 z-100 box-content max-w-[var(--page-width)]": true,
			":c: grid grid-cols-[repeat(5,minmax(4.25rem,1fr))] items-center justify-items-center select-none p-bottom-safe rounded-t-4 bg-gray-800 h-15": true
		}}
	>
		<Show when={showOrderMenu()}>
			<div class=":c: fixed left-0 right-0 max-w-[var(--page-width)] bottom-0 h-32.5 z-[-1] bg-red" style={{ background: "linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, #2C2C2C 100%)" }}>
				<div class=":c: abs-centered-x flex items-center gap-2.5">
					<div class=":c: w-38.5 h-10 flex-center rounded-6px text-4 font-400 bg-#D6FE1E relative ptr"
						ref={ripple}
						innerText={t().order}
						onClick={() => {
							showOrderMenu(false)
							router.stack(ROUTES.order.template, { animation: "bottom-sheet" })
						}}
					/>
					<div class=":c: w-38.5 h-10 flex-center rounded-6px text-4 font-400 bg-white relative ptr"
						ref={ripple}
						innerText={t().topup}
						onClick={() => {
							showOrderMenu(false)
							router.stack(ROUTES.balance_sheet.template, { animation: "bottom-sheet" })
						}}
					/>
				</div>
			</div>
		</Show>

		<SimpleMenuItem
			icon="i-local:home" text={t()[ROUTES.main.template]}
			route={ROUTES.main.template}
		/>
		<Show
			when={!auth.is_authenticated}
			fallback={
				<SimpleMenuItem
					icon="i-local:earth" text={t()[ROUTES.bonus_main.template]}
					route={ROUTES.bonus_main.template}
				/>
			}>
			<div class=":c: flex flex-col items-center justify-between h-9 w-full ptr"
				onClick={() => router.stack(ROUTES.login.template)}
			>
				<i classList={{
					"i-local:earth": true,
					":c: size-5 c-gray-300": true,
				}}
				/>
				<span
					classList={{ [name_class]: true }}
					innerText={t()[ROUTES.bonus_main.template]}
				/>
			</div>
		</Show>
		<div class=":c: flex flex-col items-center justify-between w-full h-9">
			<div class=":c: shrink-0 absolute top-0 translate-y-[-50%] rounded size-10 bg-green-500 flex-center ptr z-100"
				onClick={() => showOrderMenu(v => !v)}
			>
				<svg classList={{ ":c size-4.5 transition-(duration-100 property-[transform] ease-linear)": true, ":c: rotate-[-45deg]": showOrderMenu() }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.63654 0.818359H10.3638V17.182H7.63654V0.818359Z" fill="black" />
					<path d="M0.818359 7.63654H17.182V10.3638H0.818359V7.63654Z" fill="black" />
				</svg>
			</div>
			<div class=":c: content-visibility-hidden size-10 z-0" />
			<span
				class={name_class}
				innerText={t()[ROUTES.order.template]}
			/>
		</div>
		<SimpleMenuItem
			icon="i-local:support" text={t()[ROUTES.help.template]}
			route={ROUTES.help.template}
		/>
		<SimpleMenuItem
			icon="i-local:settings" text={t()[ROUTES.more.template]}
			route={ROUTES.more.template}
		/>
	</div>
}

let MOBILE_NAVBAR_FORBIDDEN_ROUTES = [
	ROUTES.onboarding,

	ROUTES.login,
	ROUTES.index,
	
	ROUTES.order,
	ROUTES.order_completed,

	ROUTES.balance_sheet,
	ROUTES.balance_stacked,
	ROUTES.balance_completed,
	
	ROUTES.history,

	ROUTES.languages,

	ROUTES.compatibility,
	ROUTES.guide,

	ROUTES.bonus_anon,
	ROUTES.bonus_stacked,
	ROUTES.terms,
	ROUTES.privacy,
	ROUTES.referal_program,
	ROUTES.contacts,

	ROUTES.fake_payment,
	ROUTES.payment_callback,

	ROUTES.all,
] as const

export let useIsMobileNavbarAvailable = (view = () => useRouter().view) => {
	let auth = useAuth()

	return createMemo(() => {
		let v = view()

		if (!v) {
			return false
		}

		if (MOBILE_NAVBAR_FORBIDDEN_ROUTES.includes(v.placeholder.route)) {
			return false
		}

		if (v.placeholder.route === ROUTES.balance_sheet && !auth.is_authenticated) {
			return false
		}

		return true
	})
}

export function MobileNavbarPlaceholder() {
	let router = useRouter()
	let isNavbarAvailable = useIsMobileNavbarAvailable(() => router.top)

	const enter_exit_active_class = ":c: transition-(duration-250 property-[bottom,opacity] ease-[cubic-bezier(0.2,0.75,0.15,0.98)])"
	const enter_exit_class = ":c: opacity-0 bottom--12!"
	return (
		<Transition
			enterActiveClass={enter_exit_active_class}
			exitActiveClass={enter_exit_active_class}
			enterClass={enter_exit_class}
			exitToClass={enter_exit_class}
		>
			<Show when={isNavbarAvailable()}>
				<MobileBottomNavbar />
			</Show>
		</Transition>
	)
}
