import { onCleanup } from "solid-js";

export default function clickOutside(el, accessor) {
	const onClick = (e) => {
		e && !el.contains(e.target) && accessor()?.();
	};
	document.body.addEventListener("click", onClick);

	return onClick;
}
