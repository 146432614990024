// reexport lib modules
export * from "./tracing"
export * from "./auth"
export * from "./behaviour"
export * from "./schedulers"
export * from "./local_storage"

// reexport common modules
export { ROUTES, useInPageView, ViewTransition, useRouter, Navigate, onPageWasNavigated } from "./navigation/mod"
export { api } from "./remote/api"
export {
	signal,
	drop,
	Ref,
	type ComponentLike,
	type ComposableComponentLike,
	type ComposableComponentProps,
} from "./rx/mod"
export { langs, lang } from "./appearance/i18n"
export { useCache } from "./cache/cache.context"
export { useLayout } from "../layout.context"

// Lib things too
import { toast } from "solid-toast"
import EventEmitter from "eventemitter3"

import { env } from "./behaviour"
import { api } from "./remote/api"
import { type lang, LANGS_META } from "./appearance/i18n"

export const
	TEXT_ENCODER = new TextEncoder(),
	TEXT_DECODER = new TextDecoder()

export const
	MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24,
	DEFAULT_LOCALE = "ru-RU"

export let isDigit = (as_number: number) => !isNaN(as_number) && as_number >= 0 && as_number < 10

export function formatFloatLike(
	value: string | number,
	clang: ReturnType<typeof lang>,
	format_options: Partial<Intl.NumberFormatOptions> = {}
) {
	if (typeof value === "string") {
		try { value = parseFloat(value) }
		catch (e) {
			gtrace.error(e)
			value = 0.00
		}
	}

	return new Intl.NumberFormat(LANGS_META[clang].locale_name, { maximumFractionDigits: 2, ...format_options })
		.format(value)
}

export let CURRENCY_SYMBOLS = {
	rub: "₽",
	usd: "$"
} as const

export function doNextFrame<Fn extends FrameRequestCallback>(fn: Fn) {
	if (env.rt.is_firefox)
		return requestAnimationFrame(() => requestAnimationFrame(fn))

	return requestAnimationFrame(fn)
}

export let onlyDev = <T, F = T>(value: T, fallback?: F) =>
	import.meta.env.DEV ? value : fallback ?? (value?.constructor?.() as T)

export function isDiscriminant<R, E, P extends keyof E>(target: R | E | {}, prefix: P): target is E {
	return target && typeof target === "object" && prefix in target
}
export let isApiError = <T, E extends api.ApiError>(target: T | E): target is E => isDiscriminant<T | E, E, "$api_error">(target, "$api_error")

export function errorHandled<T, R extends (T | api.ApiError), E extends R extends api.ApiError ? R : never>(response: R, hide_toast?: true): response is E;
export function errorHandled<T, R extends (T | api.ApiError), E extends R extends api.ApiError ? R : never>(response: R, prefix?: string): response is E;
export function errorHandled<T, R extends (T | api.ApiError), E extends R extends api.ApiError ? R : never>(response: R, param?: string | true): response is E {
	if (isApiError(response)) {
		let parts = [], postfix: string
		if (typeof param === "string") parts.push(param)
		switch (response.$api_error) {
			case "network":
				postfix = `Проблемы с подключением`
				break
			case "parse":
			case "bad_response":
				postfix = `Неопозанный ответ от сервера. Попробуйте позже`
				break
			case "unknown":
				postfix = `Неизвестная ошибка`
				break
			case "remote":
				postfix = `${response.detail}`
				break
		}
		parts.push(postfix)
		if (param !== true) {
			toast.error(parts.join("\n"), { duration: 2500 })
		}
		return true
	}

	return false
}

export type CancelSignal = ReturnType<typeof CancelSignal>
export function CancelSignal() {
	let emitter = new EventEmitter<{ "cancelled": [] }>()
	let signal = {
		cancelled: false,
		cancel() {
			emitter.emit("cancelled")
			signal.cancelled = true
		},
		onCancelled: emitter.on.bind(emitter, "cancelled"),
	}
	return signal
}
