
import { routes } from "./common"
import { api, isApiError,  useAuth, useRouter } from "#/lib/mod"


export function TokenPage() {
	let router = useRouter()
	let auth = useAuth()


	let { location: { query: { token } } } = useRouter()

	if (token) {
		api.requestJwtFromToken({token}).then(result => {
			if (isApiError(result)) {
			} else {
				auth.tryAuthenticate(result.token).then(
					() => router.switchTo(routes.sales.template)
				)
			}
		})

	}
	return (
	<h1></h1>
	)
}

