import { langs } from "#/lib/appearance/i18n"

export let interest_langs = langs({
	ru: {
		your_interests: "Ваши интересы",
		choose_interests: "Выберите до пяти интересов",
		finish: "Закончить",
	},
	en: {
		your_interests: "Your Interests",
		choose_interests: "Choose one or more",
		finish: "Finish",
	},
})

export let activities_langs = langs({
	ru: {
		title: "Чем вы занимаетесь?",
		choose_variants: "Выберите один или несколько вариантов",
		activities: {
			Investor: "Инвестор",
			Entrepreneur: "Предприниматель",
			Freelancer: "Фрилансер",
		},
	},
	en: {
		title: "Who are you?",
		choose_variants: "Choose one or more items",
		activities: {
			Investor: "Investor",
			Entrepreneur: "Entrepreneur",
			Freelancer: "Ereelancer",
		},
	},
})

export let avatar_langs = langs({
	ru: {
		upload_picture: "Загрузите фото",
		skip: "Пропустить",
	},
	en: {
		upload_picture: "Upload photo",
		skip: "Skip",
	},
})

export let introduction_langs = langs({
	ru: {
		introduce_yourself: "Представьтесь",
		first_name: {
			label: "Имя",
			placeholder: "Владислав",
			client_error: "только латинские и кирриллические буквы",
		},
		last_name: {
			label: "Фамилия",
			placeholder: "Савельев",
			client_error: "только латинские и кирриллические буквы",
		},
	},
	en: {
		introduce_yourself: "What's your name?",
		first_name: {
			label: "Name",
			placeholder: "Tom",
			client_error: "only latin and cyrillic letters",
		},
		last_name: {
			label: "Surname",
			placeholder: "Lindemann",
			client_error: "only contain latin and cyrillic letters",
		},
	},
})
