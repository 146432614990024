import { drop, isDigit, type ComposableComponentProps } from "#/lib/mod"
import { createMemo, For } from "solid-js"
import { createMutable } from "solid-js/store"


type PinInputOptions = {
	length: number
}
export function createPinInput(opts: PinInputOptions) {
	let state = createMutable({
		focused: null as number,
		inputs: new Array(opts.length).fill(null).map(_ => ({ value: "", ref: null as HTMLInputElement })),
	})

	let focusAt = (i: number) => state.inputs[i].ref.focus({ preventScroll: true })

	let allFilled = createMemo(() =>
		state.inputs.reduce((acc, inp) => {
			let maybe_digit = Number(inp.value)

			if (!isDigit(maybe_digit))
				return acc

			return acc + 1
		}, 0) === state.inputs.length
	)

	type PinInputProps = {
		onCompleted?(code: string): void
		onInput?(e: InputEvent): void
		disabled?: boolean
	} & ComposableComponentProps<"div"> & { class?: string }

	function PinInput(props: PinInputProps) {
		let other = drop(props, "onCompleted", "class", "classList")

		return <div {...other}
			classList={{
				":c: flex justify-center gap-2 w-fit": true,
				[props.class]: !!props.class,
				...props.classList,
			}}
		>
			<For each={state.inputs}>
				{(input, index) => (
					<input
						classList={{
							":c: size-12 p-0 text-center outline-none b-(1px gray-300 solid) rounded-6px font-600": true,
							":c: uno-layer-v1:b-black": input.value?.length > 0
						}}
						inputmode="numeric"
						autocomplete="one-time-code"
						ref={r => input.ref = r}
						value={input.value}
						onKeyDown={(e, { key, currentTarget: target } = e) => {
							if (props.disabled) {
								e.preventDefault()
								return
							}

							let curr = index()
							if (key === "ArrowRight") {
								if (curr < opts.length - 1) {
									focusAt(curr + 1)
								}
								return
							}
							if (key === "ArrowLeft") {
								if (curr > 0) {
									focusAt(curr - 1)
								}
								return
							}
							if (key === "Backspace" || key === "Process") {
								if (target.value !== "") {
									return
								}
								if (index() > 0) {
									focusAt(index() - 1)
									return
								}
							}
						}}
						// Chrome + Android keyboard are pretty buggy
						// We can't handle onKeyDown https://bugs.chromium.org/p/chromium/issues/detail?id=118639
						// Also, some keyboard doesn't even send Backspace event
						onInput={(e, { inputType, data: symbol, currentTarget: target } = e) => {
							if (props.disabled) {
								e.preventDefault()
								return
							}

							props.onInput?.(e)

							if (inputType === "deleteContentBackward") {
								if (input.value !== "") {
									input.value = target.value
									return
								}
							}

							if (inputType !== "insertText") {
								target.value = input.value
								return
							}

							let as_number = Number(symbol)

							if (!isDigit(as_number)) {
								target.value = input.value
								e.preventDefault()
								return
							}

							if (input.value.length === 0) {
								input.value = symbol
								target.value = symbol
								if (index() < opts.length - 1) {
									focusAt(index() + 1)
								}
								else if (allFilled()) {
									props.onCompleted?.(state.inputs.map(input => input.value).join(""))
								}
								return
							}

							if (index() != opts.length - 1) {
								target.value = input.value
								if (state.inputs[index() + 1].value !== "") {
									return
								}
								focusAt(index() + 1)
								state.inputs[index() + 1].value = symbol
								return
							}
							target.value = input.value
						}}
						onPaste={e => {
							if (props.disabled) {
								e.preventDefault()
								return
							}

							let str_value = e.clipboardData.getData("text")
							if (str_value?.length !== opts.length) {
								return e.preventDefault()
							}
							let as_number = Number(str_value)
							if (isNaN(as_number) || as_number / Math.pow(10, opts.length) >= 10) {
								return e.preventDefault()
							}
							for (let i = 0; i < opts.length; i++) {
								state.inputs[i].value = str_value[i]
							}
							e.preventDefault()
							if (allFilled()) {
								props.onCompleted?.(state.inputs.map(input => input.value).join(""))
							}
						}}
					/>
				)}
			</For>
		</div>
	}

	return { PinInput, focusAt }
}
