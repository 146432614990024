import { Crisp } from "crisp-sdk-web";
import clickOutside from "./clickOutside";
import { createSignal } from "solid-js";

let BubbleIcon = (p) => (
	<svg viewBox="0 0 16 16" fill="none" {...p}>
		<path
			d="M8 15C12.418 15 16 11.866 16 8C16 4.134 12.418 1 8 1C3.582 1 0 4.134 0 8C0 9.76 0.743 11.37 1.97 12.6C1.873 13.616 1.553 14.73 1.199 15.566C1.12 15.752 1.273 15.96 1.472 15.928C3.728 15.558 5.069 14.99 5.652 14.694C6.41777 14.899 7.20727 15.0019 8 15Z"
			fill={p.fill}
		/>
	</svg>
);
export default (p) => {
	let handler = null;

	const closeCrisp = () => {
		Crisp.chat.close();
		if (handler) {
			document.body.removeEventListener("click", handler);
		}
	};

	Crisp.session.onLoaded(() => {
		console.log("loaded crisp");
		const crispChatDiv = document.querySelector("#crisp-chatbox div div");

		if (Crisp.chat.isChatOpened()) {
			const onClickHandler = clickOutside(crispChatDiv, () => closeCrisp());
			handler = onClickHandler;
		}
	});

	return (
		<a
			class=":c: hover:bg-#e2fe61 active:bg-#c1e51b ptr"
			onClick={() => {
				if (Crisp.chat.isChatOpened()) {
					closeCrisp();
				} else {
					Crisp.chat.open();
					const el = document.querySelector("#crisp-chatbox div div");
					if (el) {
						const h = clickOutside(el, () => closeCrisp());
						handler = h;
					}
				}
			}}
		>
			<BubbleIcon class=":c: size-6 <md:size-4" {...p} />
		</a>
	);
};
