import {
	batch,
	createContext,
	createMemo,
	onCleanup,
	useContext,
	type JSX,
	type ParentProps,
} from "solid-js";
import { createMutable } from "solid-js/store";
import { EventEmitter } from "eventemitter3";
import { toast } from "solid-toast";

import {
	api,
	errorHandled,
	isApiError,
	LocalStorageKeys,
	signal,
	useCache,
	useRouter,
} from "#/lib/mod";
import { tracing } from "./tracing";
import { replay } from "#/telemetry";
import { usePK } from "#/domains/pk/common";

type AuthState =
	| {
			$: "stale";
	  }
	| {
			$: "authenticating";
	  }
	| {
			$: "authenticated";
			user_id: string;
	  }
	| {
			$: "switching_account";
			user_id: string;
			partnership_id: string;
			currency: "bonus" | "usd" | "rub";
	  };

export let auth_emitter = new EventEmitter<{
	login: [api.GetAccountInfoResponse];
	logout: [];
}>();
export function AuthContextProvider(props: ParentProps) {
	let trace = tracing("AuthContextProvider");
	const pk = usePK();
	const router = useRouter();

	let cache = useCache();
	let store = createMutable({
		state: { $: "stale" } as AuthState,
		jwt: localStorage.getItem(LocalStorageKeys.JWT),
	});
	console.log("fff", localStorage.getItem(LocalStorageKeys.JWT));

	if (store.jwt != null && !tokenExpired(store.jwt)) {
		authenticate(store.jwt);
	}

	if (store.jwt === null && !(window.location.pathname === "/token")) {
		window.location.href = "https://unisim.net";
	}

	function tokenExpired(token: string) {
		return Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;
	}

	function tryAuthenticate(jwt: string) {
		if (store.state.$ === "authenticating") {
			trace.debug("Skipping tryAuthenticate, already authenticating");
			return;
		}
		return authenticate(jwt);
	}

	async function authenticate(jwt: string) {
		trace.debug("Authenticating");

		store.state = { $: "authenticating" };
		api.headers["Authorization"] = `Bearer ${(store.jwt = jwt)}`;

		let response = await api.getAccountInfo();

		let isNetworkError = () => {
			return isApiError(response) && response.$api_error === "network";
		};

		let a = 1;

		while (a <= 4 && isNetworkError()) {
			let timeout_ms = 2 ** a * 800 + Math.random() * 200;

			response = await api.getAccountInfo();

			let CountDown = () => (
				<ErrorCountdown
					from_ms={timeout_ms}
					Component={(props) => (
						<div
							children={[
								`Ошибка сети при аутентификации.\n`,
								`Подключение заново через ${props.countdown} с.\n`,
								`Попытка: ${a}.`,
							]}
						/>
					)}
				/>
			);
			toast.error(CountDown, {
				style: { background: "orange" },
				duration: timeout_ms - 100,
			});
			await Promise.delay(timeout_ms);
			a++;
		}

		if (isNetworkError()) {
			toast.error(
				"Не удалось подключиться к серверу, перезагрузите приложение",
			);
			store.state = { $: "stale" };
			return;
		}

		if (errorHandled(response)) {
			logout();
			return;
		}

		if (!response) {
			trace.warn("Invalid jwt");
			logout();
			return;
		}

		trace.debug("Loaded user", response);

		let user = response;
		batch(() => {
			cache.update("users", [user]);
			store.state = {
				$: "authenticated",
				user_id: user.id,
				partnership_id: user.partnership,
				currency: user.currency,
			};
		});
		pk.state.partnership_id = user.partnership;
		pk.state.currency = user.currency;
		console.log("aaaa", pk.state.partnership_id, user.partnership, response);
		replay?.setUserID(user.email);
		localStorage.setItem(LocalStorageKeys.JWT, jwt);
		auth_emitter.emit("login", user);
	}

	async function logout() {
		// This is workaround
		// I do not fully understand timings in Solid reactivity
		// Some pages that should dispose after is_authenticated becomes false, but they don't,
		// instead they trigger their effects/memos inside
		// this will avoid null exception in references to auth.user in authenticated routes

		store.state.$ = "stale";

		queueMicrotask(() => {
			store.state = { $: "stale" };

			delete api.headers["authorization"];
			if (localStorage.getItem(LocalStorageKeys.JWT)) {
				localStorage.removeItem(LocalStorageKeys.JWT);
				toast.success("You are not authenticated anymore");
			}
			cache.drop({ exclude: ["countries", "banks"] });
			auth_emitter.emit("logout");
		});
	}

	async function refetchUser() {
		let response = await api.getAccountInfo();

		if (
			errorHandled(
				response,
				"Ошибка загрузки информации о текущем пользователе",
			)
		) {
			return;
		}

		store.state = {
			$: "authenticated",
			user_id: response.id,
			partnership_id: response.partnership,
		};
		pk.state.partnership_id = response.partnership;
		pk.state.currency = response.currency;
		cache.update("users", [response]);
		return response;
	}

	// emitter.on("logout", cache.drop)
	// onCleanup(() => emitter.off("logout", cache.drop))

	let userMemo = createMemo(() => {
		return cache.resolve("users", store.state.user_id);
	});

	let authenticatedMemo = createMemo(() => {
		return (
			userMemo() != null &&
			(store.state.$ === "authenticated" ||
				store.state.$ === "switching_account")
		);
	});

	let ctx = {
		store,
		tryAuthenticate,
		logout,
		refetchUser,
		auth_emitter,
		get jwt() {
			return store.jwt;
		},
		get user() {
			return userMemo();
		},
		get is_auth_pending() {
			return store.state.$ === "authenticating";
		},
		get is_authenticated() {
			return authenticatedMemo();
		},
	};

	return Object.assign(
		<AuthContext.Provider value={ctx} children={props.children} />,
		{ ctx },
	);
}

let AuthContext =
	createContext<ReturnType<typeof AuthContextProvider>["ctx"]>();
export let useAuth = () => useContext(AuthContext);

type ErrorCountdownProps = {
	from_ms: number;
	Component?(props: { countdown: number }): JSX.Element;
};

export function ErrorCountdown(props: ErrorCountdownProps) {
	let seconds = Math.floor(props.from_ms / 1000);
	let sc = signal(seconds);

	let timeout: number;
	function countdown() {
		timeout = window.setTimeout(() => {
			let current = sc() - 1;
			if (current === 0) {
				clearTimeout(timeout);
				return;
			}
			sc(current);
			countdown();
		}, 1000 - new Date().getMilliseconds());
	}

	countdown();

	onCleanup(() => clearTimeout(timeout));

	return <props.Component countdown={sc()} />;
}
