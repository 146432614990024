import "uno.css";
import "#/assets/styles/index.css";
import "#/assets/fonts/fonts.css";

import "./lib/polyfills";
import "./lib/std";
import "./lib/globals";

import { render } from "solid-js/web";
import { Toaster } from "solid-toast";

import { I18n } from "./lib/appearance/i18n";
import { Navigate, Router } from "./lib/navigation/navigation";
import { PKContextProivder, routes } from "./domains/pk/common";
import { SalesPage } from "./domains/pk/sales.page";
import { PaymentsPage } from "./domains/pk/payments.page";
import { Meta, MetaProvider, Title } from "./lib/meta";
import { AuthContextProvider } from "./lib/auth";
import { CacheContextProvider } from "./lib/cache/cache.context";
import { TokenPage } from "./domains/pk/token.page";
import { Crisp } from "crisp-sdk-web";

let dispose: () => void;

void (function bootstrap() {
	if (import.meta.hot) {
		document.body.replaceChildren();
		mount();

		import.meta.hot.dispose(dispose);
		import.meta.hot.accept(Function.NOOP);
	} else mount();
})();

function mount() {
	dispose = render(App, document.body);
}

function App() {
	Crisp.configure(UNISIM_CRISP_WEBSITE_ID);
	Crisp.setZIndex(100000000);
	return [
		<MetaProvider>
			<Title children="eSIM для путешествий UNISIM – кабинет партнера" />
			<Meta name="description" content="Кабинет партнера UNISIM" />
			<I18n>
				<PKContextProivder>
					<CacheContextProvider>
						<AuthContextProvider>
							<Router>
								<Router.Routes>
									<Router.RoutePlaceholder
										route={routes.sales}
										component={SalesPage}
										preserve
									/>
									<Router.RoutePlaceholder
										route={routes.payments}
										component={PaymentsPage}
										preserve
									/>
									<Router.RoutePlaceholder
										route={routes.token}
										component={TokenPage}
										preserve
									/>
									<Router.RoutePlaceholder
										route={{ template: "/*" }}
										component={() => (
											<Navigate to={routes.sales.template} />
										)}
									/>
								</Router.Routes>
							</Router>
						</AuthContextProvider>
					</CacheContextProvider>
				</PKContextProivder>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{
				position: "bottom-center",
				duration: 1550,
			}}
		/>,
	];
}
