import { batch, createContext, useContext, type ParentProps } from "solid-js"
import { createMutable } from "solid-js/store"

import { mergeMutableAdditive } from "#/lib/rx/data"

import { api } from "../mod"


export function CacheContextProvider(props: ParentProps) {
	let trace = tracing("CacheContext")

	let entities = createMutable({
		users: [] as (api.GetAccountInfoResponse)[],
		countries: [] as api.GetRatesResonse["results"],
		banks: [] as api.GetBanksResponse,
		esims: [] as api.GetEsimsResponse["results"],
		operations: [] as api.GetOperationsResponse,
	})

	type EntitiesStorage = typeof entities
	type SpecialKeys = Partial<
		{
			[key in keyof EntitiesStorage]: keyof EntitiesStorage[key][number] extends string
			? keyof EntitiesStorage[key][number]
			: never
		}
	>

	let special_keys: SpecialKeys = {
		countries: "name",
		banks: "currency_slug",
	}

	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		id: string,
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K][number]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		ids: string[],
		key?: keyof EntitiesStorage[K][number],
	): EntitiesStorage[K]
	function resolve<K extends keyof EntitiesStorage>(
		name: K,
		ids: string | string[],
		key = special_keys[name] ?? "id",
	) {
		if (Array.isArray(ids)) {
			let resolved = ids.flatMap(num => entities[name].filter(item => item[key as keyof typeof item] === num))
			return resolved.filter(Boolean)
		}
		let resolved = entities[name].find(item => item[key as keyof typeof item] === ids)
		return resolved
	}

	function update<K extends keyof EntitiesStorage>(key: K, values: EntitiesStorage[K]) {
		type V = EntitiesStorage[K][number]
		mergeMutableAdditive<V, V>(entities[key], values, {
			merge: true,
			key: special_keys[key] ?? "id",
		})
	}

	function updateBatch<K extends keyof EntitiesStorage>(obj: Record<K, EntitiesStorage[K]>) {
		batch(() => Object.keys(obj).forEach(key => (key in entities) && update(key as K, obj[key])))
	}

	function drop({ exclude = [] as (keyof EntitiesStorage)[] } = {}) {
		batch(() => {
			for (let storage of Object.getOwnPropertyNames(entities)) {
				if (!exclude.includes(storage))
					entities[storage].splice(0, entities[storage].length)
			}
		})
		trace.info("in-memory cache cleared")
	}

	let ctx = {
		entities,
		resolve,
		update,
		updateBatch,
		drop,
	}

	return Object.assign(<CacheContext.Provider value={ctx} children={props.children} />, { ctx })
}

let CacheContext = createContext<ReturnType<typeof CacheContextProvider>["ctx"]>()
export let useCache = () => useContext(CacheContext)
