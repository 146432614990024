import {
	ComponentProps,
	createComponent,
	createEffect,
	createMemo,
	createSignal,
	For,
	JSX,
	Match,
	onMount,
	Show,
	Switch,
} from "solid-js";
import { createMutable } from "solid-js/store";

import {
	api,
	CURRENCY_SYMBOLS,
	drop,
	lang,
	langs,
	onPageWasNavigated,
	useRouter,
} from "#/lib/mod";
import {
	DropdownSection,
	useDropdownSelectionContext,
} from "#/components/dropdown-section";
import { type Lang } from "#/lib/appearance/i18n";
import {
	formatNumberSplitThousands as format,
	md,
	PartnersCabinetPageLayout,
	routes,
	toISODateString,
	UnisimCoin,
	usePK,
} from "./common";
import { createDateRangePicker } from "./date_range_picker";
import { Crisp } from "crisp-sdk-web";
import clickOutside from "#/lib/clickOutside";
import CrispButton from "#/lib/crispButton";

function hint(target: HTMLElement, where: () => HTMLElement, text: string) {
	let hint: HTMLElement;
	target.addEventListener("mouseenter", ({ currentTarget }) => {
		function close(e?: MouseEvent) {
			hint.remove();
			target.removeEventListener("mouseleave", close);
		}
		if (hint)
			try {
				close();
			} catch {}

		hint = (
			<div
				class=":c: absolute flex-center bg-white c-#4d4d4d text-3.5 font-300 p-3 w-80 rounded-8px z-1000"
				style={{
					"box-shadow": "4px 9px 20px 0 #757e9f40",
					visibility: "hidden",
				}}
				children={text}
			/>
		) as HTMLDivElement;
		where().appendChild(hint);

		let page_width = Math.min(window.innerWidth, 1440);
		let page_right = page_width + (window.innerWidth - page_width) / 2;

		let target_rect = currentTarget.getBoundingClientRect();
		let rel_pos = where().getBoundingClientRect();
		let safe_x = Math.clamp(
			target_rect.left - rel_pos.left,
			0,
			page_right - rel_pos.left - hint.getBoundingClientRect().width - 50,
		);

		hint.style.left = safe_x + "px";
		hint.style.top =
			target_rect.top - rel_pos.top + target_rect.height + 12 + "px";

		hint.style.visibility = null;

		target.addEventListener("mouseleave", close);
	});
}

export function SalesPage() {
	let trace = tracing("SalesPage");
	let router = useRouter();
	let pk = usePK();
	const [downloadLoading, setDownloadLoading] = createSignal(false);

	let sales = createMutable({
		range: {
			start: null as number,
			end: null as number,
		},
		metrics: null as api.GetMetricsForPeriodResponse,
	});
	let DateRangePicker = createDateRangePicker();

	onPageWasNavigated((view, pview) => {
		const start = Date.parse(router.location.query.start);
		const end = Date.parse(router.location.query.end);

		if ([start, end].every((i) => !isNaN(i))) {
			DateRangePicker.start(start);
			DateRangePicker.end(end);
		}
		if (view.rpath !== pview?.rpath) {
			refetchMetrics();
		}
	});

	async function refetchMetrics() {
		if (!pk.state.partnership_id) {
			return;
		}
		trace.debug("refetchMetrics", sales.range, pk.state.partnership_id);
		let response = await api.getMetricsForPeriod({
			period_start: toISODateString(sales.range.start),
			period_end: toISODateString(sales.range.end),
		});
		sales.metrics = response;
		pk.state.currency = sales.metrics.currency;

		trace.debug("received metrics", response);
	}

	const downloadOrdersSpreadsheet = async () => {
		console.log(downloadLoading());
		if (downloadLoading()) {
			console.log("ignored");
			return;
		}
		setDownloadLoading(true);
		const result: Blob = await api.getOrdersSpreadsheet({
			period_start: toISODateString(sales.range.start),
			period_end: toISODateString(sales.range.end),
		});
		console.log(result);
		const file = window.URL.createObjectURL(result);
		window.location.assign(file);
		setDownloadLoading(false);
	};

	createEffect(async () => {
		if (pk.state.partnership_id) {
			await refetchMetrics();
		}
	});

	let DownloadBtn = (props) => (
		<div
			{...props}
			classList={{
				...props.classList,
				":c: text-5 font-600 leading-25px w-85 rounded-8px select-none ptr":
					true,
				":c: flex-center gap-2 p-block-4 relative self-end": true,
				":c: md:(bg-black hover:bg-#333333 active:bg-#191919 c-white)":
					true,
				":c: <md:(text-4 w-full p-block-3 c-black bg-#C3CAD4 [&>svg>path]:fill-black active:bg-#C3CAD4/90)":
					true,
			}}
		>
			Скачать детализацию
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path
					d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"
					fill="white"
				/>
			</svg>
		</div>
	);

	let MetricaCard = (props) => (
		<card
			{...props}
			classList={{
				...props.classList,
				":c: flex flex-col w-full md:max-w-67 h-62 p-5 b-(1px solid #DBE0E7) rounded-16px":
					true,
				":c: <md:(h-22.5 p-4 flex-row items-center justify-between)": true,
			}}
		>
			<div class=":c: <md:(grow-1 max-w-60%)">
				<div class=":c: text-6 font-600 leading-30px text-balance <md:(text-4 leading-20px)">
					{props.title}
				</div>
				<div class=":c: text-5 font-200 leading-25px c-#4D4D4D m-t-1 <md:(text-3.5 font-300 leading-18px)">
					{props.subtitle}
				</div>
			</div>
			<div class=":c: text-9 font-700 leading-45px c-#4D4D4D m-t-auto min-w-max <md:(text-5 font-700 leading-20px m-t-initial)">
				{props.value}
			</div>
		</card>
	);

	function InfoBlock(props) {
		let Items = () => (
			<>
				<div>
					<div class=":c: c-#D6FE1E!" children={props.comission} />
					<div>Комиссия</div>
				</div>
				<div>
					<div>{props.income}</div>
					<div>Выручка UNISIM</div>
				</div>
				<div>
					<div>{props.conversion}</div>
					<div>
						Конверсия
						<InfoIcon
							class="ptr"
							ref={(r) =>
								hint(
									r,
									() =>
										r.parentElement.parentElement.parentElement
											.parentElement,
									props.item === "esim"
										? "Какой процент посетителей совершает покупку"
										: "Какой процент посетителей совершает пополнение",
								)
							}
						/>
					</div>
				</div>
				<div>
					<div>{props.sum_avg}</div>
					<div>
						Средний чек
						<InfoIcon
							class="ptr"
							ref={(r) =>
								hint(
									r,
									() =>
										r.parentElement.parentElement.parentElement
											.parentElement,
									props.item === "esim"
										? "Сколько в среднем посетители тратят на покупку eSIM"
										: "Сколько в среднем посетители тратят на пополнение eSIM",
								)
							}
						/>
					</div>
				</div>
			</>
		);
		let Desktop = () => (
			<infoblock class=":c: flex justify-between gap-4 min-w-max w-full p-6 rounded-16px bg-#2C2C2C relative">
				<div class=":c: min-w-max flex flex-col">
					<div
						children={format(props.count, pk.state.currency)}
						class=":c: text-15 font-700 leading-75px c-#BAE300"
					/>
					<div
						children={props.title}
						class=":c: text-8 font-600 leading-40px c-white m-t-auto"
					/>
					<div
						children={props.subtitle}
						class=":c: text-5 font-200 leading-25px c-#ABABAB"
					/>
				</div>
				<div
					classList={{
						":c: grid grid-cols-4 <lg:grid-cols-2 max-w-70% gap-2 rounded-8px w-full":
							true,
						":c: [&>div]:(h-18 flex flex-col justify-between rounded-8px p-inline-3 p-block-2 self-end bg-#4D4D4D)":
							true,
						":c: [&>div>div:nth-child(1)]:(text-6 font-600 leading-30px c-white min-w-max)":
							true,
						":c: [&>div>div:nth-child(2)]:(flex items-center gap-2.5 text-4 font-200 leading-20px c-#ABABAB min-w-max)":
							true,
					}}
					children={<Items />}
				/>
			</infoblock>
		);
		let Mobile = () => (
			<infoblock class=":c: flex flex-col w-full p-4 rounded-12px bg-#2C2C2C m-t-4 relative">
				<div class=":c: min-w-max flex flex-col">
					<div
						children={format(props.count, pk.state.currency)}
						class=":c: text-10 font-700 leading-50px c-#BAE300"
					/>
					<div
						children={props.title}
						class=":c: text-6 font-700 leading-30px c-white m-t-4"
					/>
					<div
						children={props.subtitle}
						class=":c: text-4 font-300 leading-20px c-#ABABAB m-t-1"
					/>
				</div>
				<div
					classList={{
						":c: grid grid-cols-2 gap-2 w-full m-t-6": true,
						":c: [&>div]:(h-11 flex flex-col justify-between)": true,
						":c: [&>div>div:nth-child(1)]:(text-4 font-600 leading-20px c-white)":
							true,
						":c: [&>div>div:nth-child(2)]:(flex items-center gap-1 text-3.5 font-300 leading-18px c-#ABABAB)":
							true,
					}}
					children={<Items />}
				/>
			</infoblock>
		);
		return (
			<Switch>
				<Match when={!md()}>
					<Desktop />
				</Match>
				<Match when={md()}>
					<Mobile />
				</Match>
			</Switch>
		);
	}

	let plural_cardinal = new Intl.PluralRules("ru-RU", { type: "cardinal" });

	return (
		<PartnersCabinetPageLayout>
			<div class=":c: m-t-12 w-full flex justify-between [&>*]:shrink-0">
				<DateRangePicker.Component
					onRangeSelected={(start, end) => {
						if (
							[start, end].every(
								(i) => typeof i === "number" && !isNaN(i),
							)
						) {
							sales.range = { start, end };
							router.commit(
								`${routes.sales.template}?start=${toISODateString(start)}&end=${toISODateString(end)}`,
							);
							refetchMetrics();
						}
					}}
				/>
				<Show
					when={!md()}
					children={
						<DownloadBtn onClick={() => downloadOrdersSpreadsheet()} />
					}
				/>
			</div>
			<Show
				when={sales.metrics && sales.metrics.esim_purchases_metrics}
				children={(function () {
					return (
						<metrics class=":c: self-stretch m-t-25 <md:m-t-6">
							<div class=":c: text-12 <md:text-6 font-700">Метрики</div>
							<cards class=":c: w-full grid grid-cols-4 gap-4 m-t-12 <lg:(grid-cols-2 [&>card]:max-w-unset) <md:(grid-cols-1 gap-2 m-t-6)">
								<MetricaCard
									title="Комиссия"
									subtitle="Сколько вы заработали за выбранный период"
									value={[
										format(
											sales.metrics.fee_earned,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus" ? (
											<UnisimCoin class=":c: size-6 <md:size-3.5 [&>path]:(fill-black stroke-black stroke-width-0.9)" />
										) : (
											CURRENCY_SYMBOLS[pk.state.currency]
										),
									]}
									classList={{
										":c: uno-layer-v1:(b-none bg-#D6FE1E) [&>div:nth-child(2)]:c-black!":
											true,
									}}
								/>
								<MetricaCard
									title="Число заказов"
									subtitle="Количество покупок и пополнений eSIM"
									value={format(
										sales.metrics.orders_count,
										pk.state.currency,
									)}
								/>
								<MetricaCard
									title="Посетители сайта"
									subtitle="Сколько пользователей пришло по вашей ссылке"
									value={format(
										sales.metrics.unique_visitors,
										pk.state.currency,
									)}
								/>
								<MetricaCard
									title="Выручка UNISIM"
									subtitle="Выручка от всех покупок и пополнений"
									value={[
										format(
											parseFloat(
												sales.metrics.esim_purchases_metrics
													.total_revenue,
											) +
												parseFloat(
													sales.metrics.balance_topups_metrics
														.total_revenue,
												),
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus"
											? CURRENCY_SYMBOLS["usd"]
											: CURRENCY_SYMBOLS[pk.state.currency],
									]}
								/>
							</cards>
							<div class=":c: flex flex-col gap-4 m-t-6 <md:(gap-2 m-t-4)">
								<InfoBlock
									count={
										sales.metrics.esim_purchases_metrics.orders_count
									}
									title="eSIM"
									item="esim"
									subtitle="Сколько было покупок"
									comission={[
										format(
											sales.metrics.esim_purchases_metrics
												.fee_earned,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus" ? (
											<UnisimCoin class=":c: size-4 <md:size-2.5 [&>path]:(fill-#D6FE1E stroke-#D6FE1E stroke-width-0.4)" />
										) : (
											CURRENCY_SYMBOLS[pk.state.currency]
										),
									]}
									income={[
										format(
											sales.metrics.esim_purchases_metrics
												.total_revenue,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus"
											? CURRENCY_SYMBOLS.usd
											: CURRENCY_SYMBOLS[pk.state.currency],
									]}
									conversion={[
										format(
											sales.metrics.esim_purchases_metrics
												.conversion_rate,
											pk.state.currency,
										),
										"%",
									]}
									sum_avg={[
										format(
											sales.metrics.esim_purchases_metrics.aov,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus"
											? CURRENCY_SYMBOLS.usd
											: CURRENCY_SYMBOLS[pk.state.currency],
									]}
								/>
								<InfoBlock
									count={
										sales.metrics.balance_topups_metrics.orders_count
									}
									title={(function () {
										switch (
											plural_cardinal.select(
												sales.metrics.balance_topups_metrics
													.orders_count,
											)
										) {
											case "one":
												return "Пополнение";
											case "few":
												return "Пополнения";
											default:
												return "Пополнений";
										}
									})()}
									item="balance"
									subtitle="Сколько было пополнений"
									comission={[
										format(
											sales.metrics.balance_topups_metrics
												.fee_earned,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus" ? (
											<UnisimCoin class=":c: size-4 <md:size-2.5 [&>path]:(fill-#D6FE1E stroke-#D6FE1E stroke-width-0.4)" />
										) : (
											CURRENCY_SYMBOLS[pk.state.currency]
										),
									]}
									income={[
										format(
											sales.metrics.balance_topups_metrics
												.total_revenue,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus"
											? CURRENCY_SYMBOLS.usd
											: CURRENCY_SYMBOLS[pk.state.currency],
									]}
									conversion={[
										format(
											sales.metrics.balance_topups_metrics
												.conversion_rate,
											pk.state.currency,
										),
										"%",
									]}
									sum_avg={[
										format(
											sales.metrics.balance_topups_metrics.aov,
											pk.state.currency,
										),
										" ",
										pk.state.currency === "bonus"
											? CURRENCY_SYMBOLS.usd
											: CURRENCY_SYMBOLS[pk.state.currency],
									]}
								/>
							</div>
						</metrics>
					);
				})()}
			/>
			<Show
				when={md()}
				children={<DownloadBtn classList={{ ":c: m-t-6": true }} />}
			/>
			<Settings />
			<a
				classList={{
					":c: text-6 font-200 c-#9CBE00 underline flex items-center m-t-18 ptr":
						true,
					":c: hover:c-#6e8600 [&>svg>path]:hover:fill-#6e8600": true,
					":c: <md:(text-4 font-300 m-l-4 m-t-8.5)": true,
				}}
				href="https://unisim.net/ru/referral-program-rules"
				target="_blank"
				children={[
					t().referal_rules,
					<svg
						class=":c: size-5.5 <md:size-3.5 m-l-1"
						viewBox="0 0 22 22"
						fill="none"
					>
						<path
							d="M17.1934 3.66654L5.80074 3.6665V4.80581V5.94507H14.443L3.66602 16.722L5.27718 18.3332L16.0541 7.55623V16.1984H17.1934H18.3327V4.80581C18.3327 4.17661 17.8226 3.66654 17.1934 3.66654Z"
							fill="#9CBE00"
						/>
					</svg>,
				]}
			/>
			<Info />
		</PartnersCabinetPageLayout>
	);
}

function Settings() {
	const pk = usePK();
	let trace = tracing("Settings");

	let settings = createMutable({
		actual: null as api.GetReferralDataResponse,
		changing: null as { percent_purchase_bonus: number } | null,
	});

	createEffect(async () => {
		if (pk.state.partnership_id) {
			await fetchRefData();
		}
	});

	async function fetchRefData() {
		let response = await api.getReferralData({
			partnership: pk.state.partnership_id,
		});
		settings.actual = response;
		pk.state.displayPromocode =
			parseFloat(response.percent_purchase_bonus) != 0;
	}

	async function onChangeClick() {
		if (!settings.changing) {
			settings.changing = {
				percent_purchase_bonus: parseInt(
					settings.actual.percent_purchase_bonus,
				),
			};
			return;
		}

		let response = await api.changeFee({
			fee: String(
				MAX_PURCHASE_FEE - settings.changing.percent_purchase_bonus,
			),
		});
		settings.actual.percent_fee_per_purchase =
			response.percent_fee_per_purchase;
		settings.actual.percent_purchase_bonus = String(
			MAX_PURCHASE_FEE - parseFloat(response.percent_fee_per_purchase),
		);
		// toast.success("Сохранено")
		settings.changing = null;
	}

	const MIN_PURCHASE_BONUS_PERCENT = 0,
		MAX_PURCHASE_BONUS_PERCENT = 5,
		MAX_PURCHASE_FEE = 10;

	function displayAsIntPercent(v: string | number) {
		return parseInt(v).toFixed(0);
	}

	let FeeEditor = () => (
		<Show
			when={settings.changing}
			fallback={
				<div class=":c: text-12 font-700 leading-40px c-#D6FE1E m-t-4.5 <md:(text-9 font-700 leading-26px m-t-2.5)">
					{displayAsIntPercent(settings.actual.percent_purchase_bonus)}%
				</div>
			}
		>
			<div class=":c: flex items-center justify-between gap-2 min-w-fit max-w-full [&>*]:shrink-0 m-t-4.5 <md:(text-9 font-700 leading-26px m-t-2.5)">
				<div
					classList={{
						":c: bg-white rounded-1 size-8 relative ptr <md:(size-7)":
							true,
						":c: uno-layer-v1:bg-gray-400 cursor-no-drop":
							settings.changing.percent_purchase_bonus ===
							MIN_PURCHASE_BONUS_PERCENT,
					}}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						settings.changing.percent_purchase_bonus >
							MIN_PURCHASE_BONUS_PERCENT &&
							--settings.changing.percent_purchase_bonus;
					}}
					onDblClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onMouseDown={(e) => {
						if (e.detail > 1) e.preventDefault();
					}}
				>
					<svg class=":c: size-4 abs-centered" fill="none">
						<path d="M2.5 7H14.5V9H2.5V7Z" fill="black" />
					</svg>
				</div>
				<div class=":c: text-12 font-700 leading-40px c-#D6FE1E select-none <md:(text-9 font-700 leading-26px)">
					{displayAsIntPercent(settings.changing.percent_purchase_bonus)}%
				</div>
				<div
					classList={{
						":c: bg-white rounded-1 size-8 relative ptr <md:(size-7)":
							true,
						":c: uno-layer-v1:bg-gray-400 cursor-no-drop":
							settings.changing.percent_purchase_bonus ===
							MAX_PURCHASE_BONUS_PERCENT,
					}}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						settings.changing.percent_purchase_bonus <
							MAX_PURCHASE_BONUS_PERCENT &&
							++settings.changing.percent_purchase_bonus;
					}}
					onDblClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					onMouseDown={(e) => {
						if (e.detail > 1) e.preventDefault();
					}}
				>
					<svg
						class=":c: size-4 abs-centered"
						viewBox="0 0 17 16"
						fill="none"
					>
						<path d="M7.5 2H9.5V14H7.5V2Z" fill="black" />
						<path d="M2.5 7H14.5V9H2.5V7Z" fill="black" />
					</svg>
				</div>
			</div>
		</Show>
	);

	function ShareBlock() {
		let getShareDescription = createMemo(() => {
			let str = `Купи eSIM для путешествий по 170+ странам с интернетом от 1 $/гб: `;
			str += settings.actual.referral_url;
			return encodeURIComponent(str);
		});
		let tg = () => `https://telegram.me/share/?url=${getShareDescription()}`;
		let ws = () =>
			`https://api.whatsapp.com/send?text=${getShareDescription()}`;
		let vk = () =>
			`http://vk.com/share.php?url=${settings.actual.referral_url}&comment=${getShareDescription()}`;
		let email = () => `mailto:?body=${getShareDescription()}`;

		let socials = [
			["i-local:telegram", tg],
			[":c: i-local:whatsapp", ws],
			["i-local:vk", vk],
			["i-local:mail", email],
		] as const;

		const links = createMemo(() => {
			return [
				[
					t().personal_link,
					settings.actual.referral_url.toLowerCase(),
					true,
				],
				[
					t().procomode,
					settings.actual.promocode.toUpperCase(),
					pk.state.displayPromocode,
				],
			];
		});

		return (
			<div class="p-inline-4 p-block-4.5 <lg:p-block-0">
				{links()
					.filter((el) => el[2])
					.map(([label, link, display]) => (
						<div class=":c: [&+&]:m-t-4">
							<span
								class=":c: text-5 font-200 leading-25px c-#4D4D4D <md:(text-4 font-300 leading-20px)"
								children={label + ":"}
							/>
							<div
								class=":c: flex items-center gap-1 m-t-1.5"
								onClick={() => {
									if (UNISIM_BUILD_PLATFORM === "web") {
										navigator.clipboard
											?.writeText(link)
											// .then(() => toast.success(t().copied))
											.catch((e) =>
												trace.error("Failed to copy link", e),
											);
									} else if (UNISIM_BUILD_PLATFORM === "android") {
										Android.invokeEvent("clipboard_copy_text", {
											label,
											content: link,
											success_text: t().copied,
										});
									}
								}}
							>
								<span
									class=":c: c-#FF5F7C font-500 text-7 leading-35px hover:underline ptr <md:(text-5 leading-20px) break-all"
									children={link}
								/>
								<i class=":c: i-local:copy size-6 <md:size-4 c-#ABABAB ptr" />
							</div>
						</div>
					))}
				<div
					class=":c: flex items-center gap-4 m-t-5"
					children={socials.map(([icon, url]) => (
						<a
							class={`:c: c-#525252 size-6 inline-block ptr ${icon}`}
							onClick={() => {
								if (UNISIM_BUILD_PLATFORM === "web")
									window.open(url(), "_blank");
								else if (UNISIM_BUILD_PLATFORM === "android")
									window.open("external:" + url(), "_top");
							}}
						/>
					))}
				/>
			</div>
		);
	}

	let card_class =
		":c: flex flex-col rounded-16px bg-#2C2C2C p-6 max-w-92 max-h-fit min-h-65 relative <md:(min-h-50 p-4)";
	let card_header_cl =
		":c: text-6 font-600 leading-30px c-white <md:(text-5 font-600 leading-25px)";
	let card_description_cl =
		":c: text-5 font-200 leading-25px c-#ABABAB m-t-2 <md:(text-4 font-300 leading-20px m-t-1)";
	let value_title_cl =
		"text-4 font-200 leading-20px c-#ABABAB <md:(text-3.5 font-300 leading-17.5px)";
	return (
		<Show when={settings.actual}>
			<settings class=":c: m-t-12.5 <md:m-t-12">
				<div class=":c: text-12 font-700 <md:(text-6 font-700 leading-30px)">
					Настройка комиссии
				</div>
				<div class=":c: flex flex-wrap gap-4 m-t-12 <lg:([&>card]:min-w-full) <md:(m-t-6)">
					<card class={card_class}>
						<div class={card_header_cl}>Комиссия</div>
						<div class={card_description_cl}>
							Сколько процентов вы получаете с покупок и пополнений eSIM
						</div>
						<div class=":c: flex gap-6 m-t-auto">
							<div>
								<div class={value_title_cl}>Покупка</div>
								<div class=":c: text-12 font-700 leading-40px c-#D6FE1E m-t-4.5 <md:(text-9 font-700 leading-26px m-t-2.5)">
									{displayAsIntPercent(
										settings.actual.percent_fee_per_purchase,
									)}
									%
								</div>
							</div>
							<div>
								<div
									classList={{
										[value_title_cl]: 1,
										":c: flex items-center gap-1.5": 1,
									}}
								>
									Пополнение{" "}
									<InfoIcon
										class="ptr"
										ref={(r) =>
											hint(
												r,
												() =>
													r.parentElement.parentElement
														.parentElement.parentElement,
												"Фиксированная комиссия за все пополнения проданных eSIM",
											)
										}
									/>
								</div>
								<div class=":c: text-12 font-700 leading-40px c-#C3CAD4 m-t-4.5 <md:(text-9 font-700 leading-26px m-t-2.5)">
									{displayAsIntPercent(
										settings.actual.percent_fee_per_topup,
									)}
									%
								</div>
							</div>
						</div>
					</card>
					<card class={card_class}>
						<div class={card_header_cl}>Бонус приглашенному</div>
						<div class={card_description_cl}>
							Бонус, который получает приглашенный пользователь
							при покупке eSIM
						</div>
						<div class=":c: flex justify-between items-end gap-6 m-t-auto">
							<div>
								<div class={value_title_cl}>Покупка</div>
								<FeeEditor />
							</div>
							<div
								class=":c: bg-#D6FE1E hover:bg-#e2fe61 active:bg-#c1e51b c-black font-600 rounded-8px p-inline-4 p-block-2 h-min relative ptr select-none"
								onClick={onChangeClick}
								children={!settings.changing ? "Изменить" : "Готово"}
							/>
						</div>
					</card>
					<ShareBlock />
				</div>
			</settings>
		</Show>
	);
}

function FaqSectionDesktop(props: ComponentProps<typeof DropdownSection>) {
	let other = drop(props, "children", "Title");

	return (
		<DropdownSection
			additional_closed_height="1.4rem"
			additional_opened_height="0.6rem"
			Container={(p) => (
				<div
					{...p}
					classList={{
						...p.classList,
						":c: box-content p-inline-4 m-inline-5 [&+&]:m-t-3 max-w-187":
							true,
						":c: [&:not(:last-child)]:b-b-(1px solid gray-200)": true,
						":c: uno-layer-v1:m-t-5": true,
						":c: uno-layer-v1:transition-ease-linear": true,
					}}
				/>
			)}
			Title={(p) => {
				let d = useDropdownSelectionContext();
				return (
					<div {...p}>
						<div class=":c: text-5 font-200 leading-25px ptr">
							<props.Title />
						</div>
						<svg
							classList={{
								"transform-origin-c transition-ease transition-duration-200 transition-property-transform size-6 m-l-auto shrink-0 ptr":
									true,
								":c: [transform:rotate(-180deg)]": d.opened(),
							}}
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
						>
							<path
								d="M12.0279 14.1131L5.06175 8.42346C4.43366 7.91046 3.51059 7.99417 2.98504 8.61179C2.44426 9.24729 2.53094 10.203 3.17722 10.7308L11.045 17.1566C11.5967 17.6087 12.3972 17.6146 12.955 17.1729L20.8083 10.9795C21.4627 10.4635 21.5659 9.51075 21.0372 8.8665C20.5229 8.23967 19.6014 8.13985 18.9648 8.64199L12.0279 14.1131Z"
								fill="#4D4D4D"
							/>
						</svg>
					</div>
				);
			}}
			{...other}
		>
			<div
				class=":c: text-4 font-200 leading-20px c-#4D4D4D [&>a]:underline [&>img]:(max-w-full) m-t-5 p-b-4"
				children={props.children}
			/>
		</DropdownSection>
	);
}
function FaqSectionMobile(props: ComponentProps<typeof DropdownSection>) {
	let other = drop(props, "children", "Title");

	return (
		<DropdownSection
			additional_closed_height="0.6rem"
			additional_opened_height="0.3rem"
			Container={(p) => (
				<div
					{...p}
					classList={{
						...p.classList,
						":c: box-content p-inline-4 m-inline-5 [&+&]:m-t-3": true,
						":c: [&:not(:last-child)]:b-b-(1px solid gray-200)": true,
						":c: uno-layer-v1:transition-ease-linear": true,
					}}
				/>
			)}
			Title={(p) => {
				return (
					<div {...p}>
						<div class=":c: font-300 leading-20px">{<props.Title />}</div>
						{p.children}
					</div>
				);
			}}
			{...other}
		>
			<div class=":c: text-3.5 font-300 leading-18px c-#4D4D4D [&>a]:underline [&>img]:(max-w-full) m-t-2 p-b-2">
				{props.children}
			</div>
		</DropdownSection>
	);
}

function Info() {
	let faqSection =
		(
			variants:
				| Record<
						Lang,
						{
							Title: JSX.Element | ((ctx: {}) => JSX.Element);
							Text: JSX.Element | ((ctx: {}) => JSX.Element);
						}
				  >
				| any,
			title_height?: string,
		) =>
		() => (
			<Switch>
				<Match when={md()}>
					<FaqSectionMobile
						title_height={title_height}
						Title={(p) => {
							let Title = () =>
								variants[lang()]?.Title ?? variants["ru"]?.Title;
							let comp = () => {
								let title = Title();
								return title === "function"
									? createComponent(title, {})
									: title;
							};
							return comp();
						}}
						children={(function () {
							let text = variants[lang()]?.Text ?? variants["ru"]?.Text;
							let comp =
								typeof text === "function"
									? createComponent(text, {})
									: text;
							return comp;
						})()}
					/>
				</Match>
				<Match when={!md()}>
					<FaqSectionDesktop
						title_height={title_height}
						Title={(p) => {
							let Title = () =>
								variants[lang()]?.Title ?? variants["ru"]?.Title;
							let comp = () => {
								let title = Title();
								return title === "function"
									? createComponent(title, {})
									: title;
							};
							return comp();
						}}
						children={(function () {
							let text = variants[lang()]?.Text ?? variants["ru"]?.Text;
							let comp =
								typeof text === "function"
									? createComponent(text, {})
									: text;
							return comp;
						})()}
					/>
				</Match>
			</Switch>
		);
	let faqs = [
		faqSection({
			ru: {
				Title: "Как работает программа?",
				Text: (
					<>
						Размещайте ссылку на собственном ресурсе (например, в канале
						или на сайте) и получаете вознаграждение за каждую покупку
						eSIM и все ее последующие пополнения привлеченными вами
						пользователями. Проверить метрики и выгрузить детализацию
						заказов можно на вкладке «Продажи». Вывести комиссию можно на
						вкладке «Выплаты». Полные правила программы доступны по{" "}
						<a
							href="https://unisim.net/ru/referral-program-rules"
							target="_blank"
						>
							ссылке
						</a>
						.
					</>
				),
			},
		}),
		faqSection({
			ru: {
				Title: "Как работает бонус приглашенному? ",
				Text: (
					<>
						Вы можете поделиться комиссией за покупку eSIM с привлекаемыми
						пользователями. Для этого измените условия начисления комиссии
						в разделе «Настройка комиссии» на вкладке «Продажи».
						Пользователь получить бонус в процентах от суммы первой
						покупки eSIM на баланс. Ваше вознаграждение будет уменьшено
						соотвественно.
					</>
				),
			},
		}),
		faqSection({
			ru: {
				Title: "Как выплачивается комиссия?",
				Text: (
					<>
						Комиссия доступна к выплате на 15-й день после заказа (покупки
						или пополнения eSIM). Вывести комиссию можно на вкладке
						«Выплаты». Например, если eSIM была куплена 1 июня, то
						комиссия будет начислена 16 июня.
					</>
				),
			},
		}),
		faqSection({
			ru: {
				Title: "Где можно размещать ссылку?",
				Text: (
					<>
						Ссылку можно размещать только на собственных ресурсах: блоге,
						канале, сайте и других. Запрещено использовать платные
						инструменты продвижения (таргетированная, поисковая и любая
						другая реклама) и инструменты SEO.
					</>
				),
			},
		}),
		faqSection({
			ru: {
				Title: "Как заказать выплату комиссии?",
				Text: <>Вывести комиссию можно на вкладке «Выплаты».</>,
			},
		}),
	];

	let ContactUs = (p) => (
		<>
			<div class=":c: text-6 font-200 leading-30px m-t-2 <md:(text-4 font-300 leading-20px m-l-4 m-t-10)">
				Не нашли ответа?
				<br />
				Свяжитесь с нами
			</div>
			<div>
				<div class=":c: flex gap-2 m-inline-auto m-t-9 [&>a]:(flex-center rounded-8px p-inline-5.5 p-block-4 bg-green-500) <md:(m-l-4 m-t-3) <md:[&>a]:(p-inline-3.5 p-block-2)">
					<a
						class=":c: hover:bg-#e2fe61 active:bg-#c1e51b"
						href="https://t.me/unisim_support"
						target="_blank"
						onClick={(e) => {
							e.preventDefault();
							let url = "https://t.me/unisim_support";
							if (UNISIM_BUILD_PLATFORM === "web") {
								window.open(url, "_blank");
							} else if (UNISIM_BUILD_PLATFORM === "android") {
								window.open("external:" + url, "_top");
							}
						}}
					>
						<TgIcon class=":c: size-6 <md:size-4" />
					</a>
					<CrispButton fill="#8CAB00" />
				</div>
			</div>
		</>
	);
	return (
		<div class=":c: w-full p-block-12.5 flex md:gap-12 m-t-12.5 <md:(flex-col m-t-0)">
			<div class=":c: flex flex-col <md:(flex-wrap flex-col-reverse)">
				<div class=":c: text-12 font-700 leading-52px <md:(text-6 leading-30px)">
					FAQ
				</div>
				<Show when={!md()}>
					<ContactUs />
				</Show>
			</div>
			<div class=":c: flex flex-col grow-1 <md:m-t-6 [&>div]:m-inline-0">
				<For each={faqs} children={(Comp) => <Comp />} />
			</div>
			<Show when={md()}>
				<ContactUs />
			</Show>
		</div>
	);
}

let t = langs({
	ru: {
		personal_link: "Ваша ссылка",
		procomode: "Промокод",
		copied: "Скопировано",
		referal_rules: "Правила программы",
	},
	en: {
		personal_link: "Your personal link",
		procomode: "Promocode",
		copied: "Copied",
		referal_rules: "About referal program",
	},
});

let InfoIcon = (props, other = drop(props, "class", "classList")) => (
	<svg
		viewBox="0 0 20 20"
		fill="none"
		{...other}
		classList={{
			...props.classList,
			[props.class]: !!props.class,
			":c: [&>path]:hover:fill-#D6FE1E! size-5 <md:size-3.5": true,
		}}
	>
		<path
			d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM10 16.875C8.64026 16.875 7.31105 16.4718 6.18046 15.7164C5.04987 14.9609 4.16868 13.8872 3.64833 12.6309C3.12798 11.3747 2.99183 9.99237 3.2571 8.65875C3.52238 7.32513 4.17716 6.10013 5.13864 5.13864C6.10013 4.17716 7.32514 3.52237 8.65876 3.2571C9.99238 2.99183 11.3747 3.12798 12.631 3.64833C13.8872 4.16868 14.9609 5.04987 15.7164 6.18045C16.4718 7.31104 16.875 8.64025 16.875 10C16.8729 11.8227 16.1479 13.5702 14.8591 14.8591C13.5702 16.1479 11.8227 16.8729 10 16.875ZM11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75ZM8.75 6.5625C8.75 6.37708 8.80499 6.19582 8.908 6.04165C9.01101 5.88748 9.15743 5.76732 9.32874 5.69636C9.50004 5.62541 9.68854 5.60684 9.8704 5.64301C10.0523 5.67919 10.2193 5.76848 10.3504 5.89959C10.4815 6.0307 10.5708 6.19775 10.607 6.3796C10.6432 6.56146 10.6246 6.74996 10.5536 6.92127C10.4827 7.09257 10.3625 7.23899 10.2084 7.342C10.0542 7.44502 9.87292 7.5 9.6875 7.5C9.43886 7.5 9.20041 7.40123 9.02459 7.22541C8.84878 7.0496 8.75 6.81114 8.75 6.5625Z"
			fill="#ABABAB"
		/>
	</svg>
);

let BubbleIcon = (p) => (
	<svg viewBox="0 0 16 16" fill="none" {...p}>
		<path
			d="M8 15C12.418 15 16 11.866 16 8C16 4.134 12.418 1 8 1C3.582 1 0 4.134 0 8C0 9.76 0.743 11.37 1.97 12.6C1.873 13.616 1.553 14.73 1.199 15.566C1.12 15.752 1.273 15.96 1.472 15.928C3.728 15.558 5.069 14.99 5.652 14.694C6.41777 14.899 7.20727 15.0019 8 15Z"
			fill="#8CAB00"
		/>
	</svg>
);

let TgIcon = (p) => (
	<svg viewBox="0 0 26 22" fill="none" {...p}>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M25.6347 2.3035C25.2847 5.99017 23.768 14.9502 22.998 19.0802C22.6714 20.8302 22.018 21.4135 21.4114 21.4835C20.3296 21.5768 19.4565 20.9544 18.4824 20.2599C18.2378 20.0856 17.9869 19.9067 17.7247 19.7335C16.6922 19.053 15.8839 18.5023 15.0832 17.9567C14.2916 17.4174 13.5074 16.8831 12.5214 16.2335C10.5229 14.9213 11.3711 14.1157 12.4975 13.0459C12.6731 12.8791 12.8554 12.706 13.0347 12.5235C13.1067 12.4515 13.417 12.164 13.8653 11.7485C15.5954 10.1452 19.3821 6.63599 19.4747 6.24684C19.4909 6.17262 19.4888 6.09557 19.4684 6.02238C19.4481 5.94918 19.4102 5.88206 19.358 5.82684C19.2337 5.72324 19.0726 5.74843 18.9237 5.77171C18.9049 5.77465 18.8863 5.77756 18.868 5.78017C18.658 5.82684 15.3914 7.99684 9.02138 12.2902C8.08804 12.9202 7.24804 13.2468 6.50137 13.2235C5.66137 13.2002 4.07471 12.7568 2.88471 12.3602C2.73267 12.3119 2.58412 12.2659 2.44008 12.2213C1.19143 11.8344 0.281028 11.5524 0.364708 10.8202C0.411375 10.4002 0.994709 9.98017 2.09138 9.53683C8.90471 6.5735 13.4314 4.6135 15.6947 3.68017C22.1814 0.973504 23.5114 0.506836 24.398 0.506836C24.5847 0.506836 25.028 0.553502 25.308 0.786836C25.5414 0.973502 25.6114 1.23017 25.6347 1.41684C25.6237 1.48312 25.6283 1.61217 25.6338 1.76437C25.6399 1.93362 25.647 2.1315 25.6347 2.3035Z"
			fill="#8CAB00"
		/>
	</svg>
);
