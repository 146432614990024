import { createContext, createEffect, on, onMount, useContext, type ComponentProps, type ParentProps } from "solid-js"
import { signal, doNextFrame, type ComponentLike, drop } from "#/lib/mod"

type DropdownSectionProps = {
	Container?: ComponentLike<'div'>
	Title?: ComponentLike<'div'>,
	title_height?: string
	additional_closed_height?: string
	additional_opened_height?: string
} & ParentProps
export function DropdownSection(props: DropdownSectionProps) {
	let {
		Container = p => <div {...p} />,
		Title = p => <div class="font-300" {...p} />
	} = props

	let ctx = {
		opened: signal(false),
		locked: signal(false),

		container: null as HTMLElement,
		title: null as HTMLElement,
		content: null as HTMLElement,
		title_height: null as string,
	}

	let { opened, locked } = ctx

	if (props.title_height == null) {
		onMount(() => {
			doNextFrame(() => {
				doNextFrame(() => {
					ctx.container.style.height = ctx.title_height = `calc(${ctx.title.getBoundingClientRect().height}px + ${props.additional_closed_height ?? "0px"})`
				})
			})
		})
	}
	else {
		onMount(() => {
			ctx.container.style.height = ctx.title_height = props.title_height
		})
	}

	createEffect(on(opened, () => {
		let is_closing: boolean

		if (opened()) {
			doNextFrame(() => ctx.container.style.height = `calc(${ctx.container.scrollHeight}px + ${props.additional_opened_height ?? "0.1rem"})`)
			// @ts-ignore
			ctx.content.style.contentVisibility = "visible"
		}
		else {
			doNextFrame(() => ctx.container.style.height = ctx.title_height)
			is_closing = true
		}

		ctx.container.addEventListener("transitionend", () => {
			// @ts-ignore
			if (is_closing) ctx.content.style.contentVisibility = "hidden"

			locked(false)
		}, { once: true })

		locked(true)

	}, { defer: true }))


	return Object.assign(
		<DropdownSectionContext.Provider value={ctx}>
			<Container
				classList={{
					":c: overflow-hidden flex flex-col": true,
					":c: transition-(property-height ease duration-150) will-change-height": true,
				}}
				style={{ "container-type": "inline-size", "height": props.title_height ?? "unset" }}
				ref={r => ctx.container = r}
			>
				<Title
					classList={{ ":c: flex items-center md:gap-1 [&>div]:m-r-2": true }}
					ref={r => ctx.title = r}
					onClick={e => {
						e.preventDefault()
						if (!locked()) opened(o => !o)
					}}
				>
					<i
						classList={{
							":c: transform-origin-c transition-(ease duration-200 property-transform)": true,
							":c: i-hero:chevron-down-solid size-4 m-l-auto shrink-0 ptr": true,
							":c: [transform:rotate(-180deg)]": opened() // do not use rotate-[-180deg] because it breaks styles in old chromium due to unocss processing
						}}
					/>
				</Title>
				<div children={props.children} ref={ref => ctx.content = ref} />
			</Container>
		</DropdownSectionContext.Provider>,
		{ ctx }
	)
}

type DropdownSectionContextType = ReturnType<typeof DropdownSection>["ctx"]
let DropdownSectionContext = createContext<DropdownSectionContextType>()
export let useDropdownSelectionContext = (useContext as typeof useContext<DropdownSectionContextType>).bind(null, DropdownSectionContext)


DropdownSection.FaqSection = function(props: ComponentProps<typeof DropdownSection>) {
	let other = drop(props, "children", "Title")

	return <DropdownSection
		additional_closed_height="0.6rem"
		additional_opened_height="0.3rem"
		Container={p =>
			<div {...p}
				classList={{
					...p.classList,
					":c: box-content [&:not(:last-child)]:b-b-(1px solid gray-200) p-inline-4 m-inline-5 [&+&]:m-t-3": true
				}}
			/>
		}
		Title={p => {
			return <div {...p}>
				<div class=":c: font-300 leading-20px">{<props.Title />}</div>
				{p.children}
			</div>
		}}
		{...other}
	>
		<div class=":c: text-sm font-200 [&>a]:underline [&>img]:(max-w-full) m-t-2 p-b-2">{props.children}</div>
	</DropdownSection>
}
