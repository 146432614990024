import {
	createContext,
	createEffect,
	createMemo,
	createRoot,
	createSignal,
	getOwner,
	on,
	onCleanup,
	useContext,
	type Accessor,
	type ParentProps,
	type JSX,
} from "solid-js"

import { auth_emitter, tracing } from "#/lib/mod"
import { LocalStorageKeys} from "#/lib/local_storage"


let trace = tracing("i18")

// https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D0%B4%D1%8B_%D1%8F%D0%B7%D1%8B%D0%BA%D0%BE%D0%B2
export let languages = ["en", "ru"] as const
export type Lang = (typeof languages)[number]
export let LANGS_META = {
	en: { locale_name: "en-US", name_native: "English", currency: "usd" },
	ru: { locale_name: "ru-RU", name_native: "Русский", currency: "rub" },
} as const

function isSupportedLocale(locale: string): locale is Lang {
	return languages.includes(locale as Lang)
}

function getInitialLang(): Lang {
	if (isSupportedLocale(UNISIM_FORCE_LANGUAGE)) {
		trace.debug("forcing", UNISIM_FORCE_LANGUAGE, "locale")
		return UNISIM_FORCE_LANGUAGE as any
	}

	let locale = localStorage.getItem(LocalStorageKeys.LANGUAGE)
	if (isSupportedLocale(locale)) {
		trace.debug("locale", locale, "loaded from cookies")
		return locale
	}

	locale = navigator.language.slice(0, 2)
	if (isSupportedLocale(locale)) {
		trace.debug("got", locale, "from navigator")
		return locale
	}

	trace.debug("fallback to default (en) locale")
	return "en"
}

type I18nContextType = {
	lang: Accessor<Lang>
	setLang: (lang: Lang) => void
}
let I18nContext = createContext<I18nContextType>({
	lang: () => "en" as const,
	setLang: () => { },
})

type Primitive = string | number | boolean
type LangFunction = (...args: Primitive[]) => string | JSX.Element
interface Dict extends Record<string, string | LangFunction | Dict> { }
type LangDict = Partial<Record<Lang, Dict>>

type UnConst<T> = T extends string
	? string
	: T extends (...args: infer A) => unknown
	? T & { __length: A['length'] }
	: {
		[K in keyof T]: UnConst<T[K]>
	}

type RemoveLength<T> = T extends string
	? string
	: T extends (...args: infer A) => infer R
	? (...args: A) => R
	: {
		[K in keyof T]: RemoveLength<T[K]>
	}

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export let [{ lang, langs, setLang }, disposeLangRoot] = createRoot((d) => {
	let initial = getInitialLang()
	const [lang, setLang] = createSignal(initial)
	function langs<const T extends LangDict>(dict: T & Record<keyof T, RemoveLength<UnionToIntersection<UnConst<T[keyof T]>>>>) {
		const handler = () => dict[lang()] as T[keyof T]
		if (getOwner()) return createMemo(handler)
		return handler
	}
	return [{ lang, setLang, langs }, d] as const
})

export function I18n(props: ParentProps<{}>) {
	auth_emitter.on("login", (user) => {
		let local_storage_locale = localStorage.getItem(LocalStorageKeys.LANGUAGE)
		if (!isSupportedLocale(local_storage_locale) && user.lang) {
			setLang(user.lang)
			trace.debug(user.lang, "locale set from backend")
		}
	})
	createEffect(on(lang, () => localStorage.setItem(LocalStorageKeys.LANGUAGE, lang()), { defer: true }))
	onCleanup(disposeLangRoot)
	return <I18nContext.Provider value={{ lang, setLang }} children={props.children} />
}

export let useI18n = () => useContext(I18nContext)
